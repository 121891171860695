
.invoices-client .table .table-body,.invoices-client .table  .table-head {
  min-width: 700px;
}

.layout .container .card.add-invoice .content {
  margin: 1rem;
  padding: 0;
  overflow-y: auto;
  .row {
    min-width: 660px;
    margin: 0;
  }
}

.add-contract .react-datepicker-popper {
  z-index: 10;
}

.vat-container {
  display: flex;
  justify-content: flex-end;

  div:last-child {
    width: 100px;
  }
}