@import "globals";

.react-datepicker button.react-datepicker__navigation{
  margin-top: 0!important;
}
// locations icon
.location-icon {
  &.small {
    width: 45px;
    height: 45px;
  }
  &.medium {
    width: 60px;
    height: 60px;
  }
}
.rs-picker-popup{
  z-index: 100000!important;
}

.location-image {
  &.small {
    width: 45px;
    height: 45px;
    border-radius: 13px;
    overflow: hidden;
    border: 1px solid $primary-border-color;
  }
  &.medium {
    width: 60px;
    height: 60px;
    border-radius: 13px;
    overflow: hidden;
    border: 1px solid $primary-border-color;
  }

  img {
    width: 100%;
  }
}
p.font-weight-bold {
  font-weight: bold;
}
@media only screen and (max-width: 480px){

  .Toastify__toast-container {
     width: unset;
     padding: 4px;
    right: 1em;
     left: unset;
     margin: 0;
    button{
      width: unset;
    }
  }
}

.badge {
  border-radius: 50%;
  color: #fff;
  display: inline-block;
  font-size: 0.75em;
  font-weight: 700;
  line-height: 1;
  padding: 0.35em;
  text-align: center;
  vertical-align: initial;
  white-space: nowrap;
  width: 11.4px;
  position: absolute;
  top: -40px;
  right: 0;
  border: 0;
  background-color: $primary-color;
}

@media only screen and (max-width: 767px){
  .my-account{
    button{
      margin-bottom: 24px;
      min-height: fit-content;
    }
  }
  .footer {
    button {
      width: 100%;
    }
  }

}
@media only screen and (max-width: 683px){
  .my-account{
    button{
      margin-bottom: 24px;
      height: fit-content;
      min-height: 2.5rem;
    }
  }

}