$primary-color: #6C5DD3; //used
$primary-white: #FFFFFF; //used
$primary-shade-1: #5c4fb6; //used
$primary-shade-2: #9D90FA; //used

$primary-background-color: #FAFBFF; //used
$secondary-background-color: #f4ebff; //used
$primary-border-color: #E6E8F0; //used

$secondary-color: #3F8CFF; //used
$secondary-shade-1: #2f6ac7; //used
$secondary-shade-2: #0049C6; //used

$accent-color-1: #FFA2C0;
$accent-color-2: #FFCE73;
$accent-color-2-shade-1: #FF754C;
$accent-color-2-shade-2: #FF9A7B;

$dark-color: #081735; //used
$error-color: #FF754C; //used

$gray-color-1: #8F95B2; //used
$gray-shade-2: #82889c; //used
$gray-color-2: #EAEBF0; //used
$gray-shade-1: #f9f9ff; //used

$link-active-color: #3F8CFF;

//Alerts


$alert-warning-background-color: #FFF3DC; //used
$alert-warning-font-color: #FF754C; //used
$alert-warning-border-color: #FFCE73;

$alert-success-background-color: #d0e6dc; //used
$alert-success-font-color: #0f5132; //used
$alert-danger-background-color: #f7d6d9; //used
$red-fasting-days: rgba(247, 214, 217, 0.3); //used
$alert-danger-font-color: #832029; //used
$alert-danger-font-color-2: red; //used


$event-1: #E7FAFF;
$event-2: #FFEBF6;
$event-3: #F1EFFB;
$event-4: #FFF3DC;
//Buttons
