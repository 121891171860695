@import "../../../styles/globals";

.login {
  width: 100%;
  min-height: 100vh;
  background: $primary-background-color;

  .logo {
    margin: auto;
    width: 180px;
    padding: 2rem 0;

    img {
      width: 100%;
    }
  }

  .container {
    display: flex;
    flex-direction: column;
    margin: auto;
    max-width: 500px;
    background: #ffffff;
    border: 1px solid $primary-border-color;
    border-radius: 8px;
    padding: 2rem 2rem;

  }
}