@import "../../../styles/globals";
ul.react-paginate {
  display: block;
  padding-left: 15px;
  padding-right: 15px;
  margin-top: 28px!important;

}

.react-paginate li {
  display: inline-flex;
  background: $primary-white;
  border: 1px solid $primary-border-color;
  box-sizing: border-box;
  border-radius: 4px;
  width: 39px;
  height: 32px;
  justify-content: center;
  text-align: center;
  align-items: center;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: rgba(34, 35, 47, 1);
  margin-right: 12px;
  user-select: none;
}

.react-paginate li a{
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
}
.react-paginate li.selected {
  background: $primary-color;
  color: $primary-white;
}

.react-paginate li.disabled i {
  opacity: 0.5;
}


@media (max-width: 576px) {
  ul.react-paginate {
    justify-content: center;
    display: flex;

  }
}