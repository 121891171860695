@import "../../../styles/globals";

.btn {
  height: 2.5rem;
  font-size: 0.95rem;
  font-weight: 600;
  letter-spacing: 0.3px;
  cursor: pointer;
  padding: 0 2rem;
  &.disabled{
    opacity: 0.5;
  }

  &.btn-primary {
    background: $primary-color;
    border:1px solid $primary-color;
    border-radius: 8px;
    color: $primary-white;

    &:hover {
      background: $primary-shade-1;
      border-color: $primary-shade-1;
    }
  }

  &.btn-secondary {
    background: $secondary-color;
    border:1px solid $secondary-color;
    border-radius: 8px;
    color: $primary-white;

    &:hover {
      background: $secondary-shade-1;
      border-color: $secondary-shade-1;
    }
  }

  &.btn-gray{
    background: $gray-color-1;
    border: 1px solid $gray-color-1;
    border-radius: 8px;
    color: $primary-white;
    font-weight: 500;

    &:hover {
      background: $gray-shade-2
    ;
    }
  }
  &.btn-light {
    background: transparent;
    border: 1px solid $primary-border-color;
    border-radius: 8px;
    color: $gray-color-1;
    font-weight: 500;

    &:hover {
      background: $gray-shade-1;
    }
  }

  &.btn-edit {
    height: 2.1rem;
    font-size: 1rem;
    line-height: 1.2rem;
    padding: 0 1rem;
    background: transparent;
    border: 0;
    border-radius: 8px;
    color: $primary-color;

    &:hover {
      background: $gray-shade-1;
    }
  }
  &.btn-delete {
    height: 2.1rem;
    font-size: 1rem;
    line-height: 1.2rem;
    padding: 0 1rem;
    background: transparent;
    border: 0;
    border-radius: 8px;
    color: $error-color;

    &:hover {
      background: $gray-shade-1;
    }
  }
  &.btn-error {
    background-color: $alert-danger-font-color;
    border-radius: 8px;
    border: 1px solid $alert-danger-font-color;
    color: $primary-white;

    &:hover {
      background: $alert-danger-font-color-2;
      border: 1px solid $alert-danger-font-color-2;
    }
  }
  &.btn-more {
    height: 2.1rem;
    font-size: 1rem;
    line-height: 1.2rem;
    padding: 0 1rem;
    background: transparent;
    border: 0;
    border-radius: 8px;
    color: $gray-color-1;

    &:hover {
      background: $gray-shade-1;
    }
  }

}
.react-tooltip {
  position: absolute;
  background: $dark-color;
  border-radius: 8px;
  color: $primary-white;
  font-weight: 400;
  padding: 4px 8px;
  min-width: fit-content;
  z-index: 10000;
}