
.form-input.rdw-editor-main{
  max-height: 700px;


}
.form-input.rdw-editor-main::-webkit-scrollbar {
  display: none;
}
.email-sent-at{
  max-width: 126px;
  text-align: center;
  font-size: 0.75rem;
}
.contracts-list{

  .table-body,.table-head{
    min-width: 920px;
  }
}