.loading-comp {
  display: flex;
  flex-direction: row;
  flex: 1;
  align-items: center;
  justify-content: center;
  min-height: 200px;
}

.loading-comp-small {

}