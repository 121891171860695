@import "../../styles/globals";
.invoice-info-container {
  margin-bottom: 20px;

  td {
    padding: 4px 0;
    width: 50%;
  }

  .client-name {
    font-size: 1.5em;
    vertical-align: top;
  }
}

.invoice-table-container {

  margin: 10px 0 20px 0;

  .heading{
    color: $gray-color-1;
  }

  th {
    text-align: left;
    padding: 10px 0 15px 0;
    font-size: 0.8rem;
    text-transform: uppercase;
    border-bottom: 2px solid $primary-border-color;
    border-right: 0;
    border-left: 0;

    &:last-child {
      text-align: right;
    }
    &.delete{
      width: 50px;
    }
  }

  td {
    padding: 15px 0;
  }

  tbody tr:first-child td {
    padding-top: 25px;
  }

  &.hasBottomBorder {

    margin-bottom: 0;

    tbody tr:last-child td {
      padding-bottom: 25px;
      border-bottom: 2px solid $primary-border-color;
    }
  }

  th.quantity{
    text-align: right;
    width: 100px;
  }
  th.description{
  }
  th.price{
    text-align: right;
    width: 100px;
  }
  th.subtotal{
    width: 100px;
  }
  td.right-aligned-cell,th.right-aligned-cell{
    text-align: right;
  }

  .payment-info{
    width: 38%;
    font-size: 0.8rem;
    line-height: 1.5;

    td.total{
      color: $primary-color;
    }
  }

  span.large{
    font-size: 1.2rem;
  }

  input, .selector__control{
    max-width: 80px;
    min-width: 60px;
    text-align: right;
    margin-left: 12px;
  }


}