:root,
.rs-theme-light {
    --rs-gray-0: #fff;
    --rs-gray-50: #f7f7fa;
    --rs-gray-100: #f2f2f5;
    --rs-gray-200: #e5e5ea;
    --rs-gray-300: #d9d9d9;
    --rs-gray-400: #b6b7b8;
    --rs-gray-500: #939393;
    --rs-gray-600: #717273;
    --rs-gray-700: #575757;
    --rs-gray-800: #343434;
    --rs-gray-900: #121212;
    --rs-primary-100: #cce9ff;
    --rs-primary-200: #a6d7ff;
    --rs-primary-400: #59afff;
    --rs-primary-500: #3498ff;
    --rs-primary-600: #2589f5;
    --rs-primary-700: #1675e0;
    --rs-primary-800: #0a5dc2;
    --rs-primary-900: #004299;
    --rs-color-red: #f44336;
    --rs-red-200: #faa9a7;
    --rs-red-400: #f7635c;
    --rs-red-500: #f44336;
    --rs-red-600: #eb3626;
    --rs-red-700: #d62915;
    --rs-red-800: #b81c07;
    --rs-red-900: #8f1300;
    --rs-orange-200: #fcc690;
    --rs-orange-400: #fa9b2f;
    --rs-orange-500: #fa8900;
    --rs-orange-600: #f08800;
    --rs-orange-700: #db8000;
    --rs-orange-800: #bd7100;
    --rs-orange-900: #945b00;
    --rs-yellow-200: #ffd991;
    --rs-yellow-400: #ffbe30;
    --rs-yellow-500: #ffb300;
    --rs-yellow-600: #f5af00;
    --rs-yellow-700: #e0a500;
    --rs-yellow-800: #c29100;
    --rs-yellow-900: #997500;
    --rs-green-200: #a5e0a4;
    --rs-green-400: #65bf67;
    --rs-green-500: #4caf50;
    --rs-green-600: #37ab3c;
    --rs-green-700: #22a12a;
    --rs-green-800: #0f9119;
    --rs-green-900: #007d0c;
    --rs-cyan-200: #87e6ed;
    --rs-cyan-400: #2acadb;
    --rs-cyan-500: #00bcd4;
    --rs-cyan-600: #00b1cc;
    --rs-cyan-700: #00a0bd;
    --rs-cyan-800: #008aa6;
    --rs-cyan-900: #006e87;
    --rs-blue-200: #9bd4fa;
    --rs-blue-400: #49abf5;
    --rs-blue-500: #2196f3;
    --rs-blue-600: #1787e8;
    --rs-blue-700: #0d73d4;
    --rs-blue-800: #045cb5;
    --rs-blue-900: #00448c;
    --rs-violet-200: #b6a1e3;
    --rs-violet-400: #805ac7;
    --rs-violet-500: #673ab7;
    --rs-violet-600: #5f2bb3;
    --rs-violet-700: #531ba8;
    --rs-violet-800: #470c99;
    --rs-violet-900: #390085;
    --rs-state-error: var(--rs-color-red);
    --rs-text-primary: var(--rs-gray-800);
    --rs-text-secondary: var(--rs-gray-600);
    --rs-text-heading: var(--rs-gray-900);
    --rs-text-inverse: var(--rs-gray-50);
    --rs-text-disabled: var(--rs-gray-600);
    --rs-text-highlight-bg: #fff6c9;
    --rs-border-primary: var(--rs-gray-200);
    --rs-bg-overlay: var(--rs-gray-0);
    --rs-color-focus-ring: rgb(from var(--rs-primary-500) r g b / 25%);
    --rs-state-focus-shadow: 0 0 0 3px rgb(from var(--rs-primary-500) r g b / 25%);
    --rs-state-error-outline: 3px solid rgb(from var(--rs-color-red) r g b / 25%);
    --rs-shadow-overlay: 0 4px 4px rgba(0, 0, 0, 0.12), 0 0 10px rgba(0, 0, 0, 0.06);
    --rs-btn-default-bg: var(--rs-gray-50);
    --rs-btn-default-text: var(--rs-gray-800);
    --rs-btn-default-hover-bg: var(--rs-gray-200);
    --rs-btn-default-active-bg: var(--rs-gray-300);
    --rs-btn-default-active-text: var(--rs-gray-900);
    --rs-btn-default-disabled-bg: var(--rs-gray-50);
    --rs-btn-default-disabled-text: var(--rs-gray-600);
    --rs-btn-primary-bg: var(--rs-primary-500);
    --rs-btn-primary-text: var(--rs-gray-0);
    --rs-btn-primary-hover-bg: var(--rs-primary-600);
    --rs-btn-primary-active-bg: var(--rs-primary-700);
    --rs-btn-subtle-text: var(--rs-gray-800);
    --rs-btn-subtle-hover-bg: var(--rs-gray-200);
    --rs-btn-subtle-hover-text: var(--rs-gray-800);
    --rs-btn-subtle-active-bg: var(--rs-gray-200);
    --rs-btn-subtle-active-text: var(--rs-gray-900);
    --rs-btn-subtle-disabled-text: var(--rs-gray-400);
    --rs-btn-ghost-border: var(--rs-primary-700);
    --rs-btn-ghost-text: var(--rs-primary-700);
    --rs-btn-ghost-hover-border: var(--rs-primary-800);
    --rs-btn-ghost-hover-text: var(--rs-primary-800);
    --rs-btn-ghost-active-border: var(--rs-primary-900);
    --rs-btn-ghost-active-text: var(--rs-primary-900);
    --rs-btn-link-text: var(--rs-primary-700);
    --rs-btn-link-hover-text: var(--rs-primary-800);
    --rs-btn-link-active-text: var(--rs-primary-900);
    --rs-loader-ring: rgb(from var(--rs-gray-50) r g b / 80%);
    --rs-loader-rotor: var(--rs-gray-500);
    --rs-loader-backdrop: rgb(from var(--rs-gray-0) r g b / 90%);
    --rs-loader-ring-inverse: rgb(from var(--rs-gray-50) r g b / 30%);
    --rs-loader-rotor-inverse: var(--rs-gray-0);
    --rs-loader-backdrop-inverse: rgb(from var(--rs-gray-900) r g b / 83%);
    --rs-input-bg: var(--rs-gray-0);
    --rs-input-focus-border: var(--rs-primary-500);
    --rs-input-disabled-bg: var(--rs-gray-50);
    --rs-listbox-option-group-bg: var(--rs-gray-0);
    --rs-listbox-option-hover-bg: rgb(from var(--rs-primary-100) r g b / 50%);
    --rs-listbox-option-hover-text: var(--rs-primary-700);
    --rs-checkbox-icon: #fff;
    --rs-checkbox-border: var(--rs-gray-300);
    --rs-checkbox-checked-bg: var(--rs-primary-500);
    --rs-checkbox-disabled-bg: var(--rs-gray-50);
    --rs-close-button-hover-color: var(--rs-color-red);
    --rs-picker-value: var(--rs-primary-700);
    --rs-picker-count-bg: var(--rs-primary-500);
    --rs-picker-count-text: #fff;
    --rs-picker-state-error: var(--rs-state-error);
    --rs-picker-state-error-outline: var(--rs-state-error-outline);
}
@supports not (color: rgb(from white r g b)) {
    :root,
    .rs-theme-light {
        --rs-color-focus-ring: rgba(52, 152, 255, 0.25);
        --rs-state-focus-shadow: 0 0 0 3px rgba(52, 152, 255, 0.25);
        --rs-loader-ring: rgba(247, 247, 250, 0.8);
        --rs-loader-backdrop: rgba(255, 255, 255, 0.9);
        --rs-loader-ring-inverse: rgba(247, 247, 250, 0.3);
        --rs-loader-backdrop-inverse: rgba(18, 18, 18, 0.83);
        --rs-listbox-option-hover-bg: rgba(204, 233, 255, 0.5);
    }
}
.rs-theme-dark {
    --rs-gray-0: #fff;
    --rs-gray-50: #e9ebf0;
    --rs-gray-100: #cbced4;
    --rs-gray-200: #a4a9b3;
    --rs-gray-300: #858b94;
    --rs-gray-400: #6a6f76;
    --rs-gray-500: #5c6066;
    --rs-gray-600: #3c3f43;
    --rs-gray-700: #292d33;
    --rs-gray-800: #1a1d24;
    --rs-gray-900: #0f131a;
    --rs-primary-100: #ccf3ff;
    --rs-primary-200: #a6e9ff;
    --rs-primary-400: #59d0ff;
    --rs-primary-500: #34c3ff;
    --rs-primary-600: #25b3f5;
    --rs-primary-700: #169de0;
    --rs-primary-800: #0a81c2;
    --rs-primary-900: #006199;
    --rs-color-red: #f04f43;
    --rs-red-200: #faa9a7;
    --rs-red-400: #f26a63;
    --rs-red-500: #f04f43;
    --rs-red-600: #e63f30;
    --rs-red-700: #d12f1d;
    --rs-red-800: #b3200c;
    --rs-red-900: #8a1200;
    --rs-orange-200: #ffce91;
    --rs-orange-400: #ffa930;
    --rs-orange-500: #ff9800;
    --rs-orange-600: #f59700;
    --rs-orange-700: #e08e00;
    --rs-orange-800: #c27e00;
    --rs-orange-900: #996600;
    --rs-yellow-200: #ffe2b3;
    --rs-yellow-400: #ffcf75;
    --rs-yellow-500: #ffc757;
    --rs-yellow-600: #f5bb3d;
    --rs-yellow-700: #e0a824;
    --rs-yellow-800: #c28f0e;
    --rs-yellow-900: #997000;
    --rs-green-200: #aee0ad;
    --rs-green-400: #71bf72;
    --rs-green-500: #58b15b;
    --rs-green-600: #3fab45;
    --rs-green-700: #27a12f;
    --rs-green-800: #10911b;
    --rs-green-900: #007d0c;
    --rs-cyan-200: #87e6ed;
    --rs-cyan-400: #2acadb;
    --rs-cyan-500: #00bcd4;
    --rs-cyan-600: #00b1cc;
    --rs-cyan-700: #00a0bd;
    --rs-cyan-800: #008aa6;
    --rs-cyan-900: #006e87;
    --rs-blue-200: #93d6fa;
    --rs-blue-400: #3dadf2;
    --rs-blue-500: #1499ef;
    --rs-blue-600: #0e8ce6;
    --rs-blue-700: #087ad1;
    --rs-blue-800: #0464b3;
    --rs-blue-900: #00498a;
    --rs-violet-200: #b6a1e3;
    --rs-violet-400: #805ac7;
    --rs-violet-500: #673ab7;
    --rs-violet-600: #5f2bb3;
    --rs-violet-700: #531ba8;
    --rs-violet-800: #470c99;
    --rs-violet-900: #390085;
    --rs-state-error: var(--rs-color-red);
    --rs-text-primary: var(--rs-gray-50);
    --rs-text-secondary: var(--rs-gray-200);
    --rs-text-heading: var(--rs-gray-0);
    --rs-text-inverse: var(--rs-gray-800);
    --rs-text-disabled: var(--rs-gray-400);
    --rs-border-primary: var(--rs-gray-600);
    --rs-bg-overlay: var(--rs-gray-700);
    --rs-color-focus-ring: 0 0 0 3px rgb(from var(--rs-gray-500) r g b / 25%);
    --rs-state-focus-shadow: 0 0 0 3px rgb(from var(--rs-gray-500) r g b / 25%);
    --rs-state-error-outline: 3px solid rgb(from var(--rs-color-red) r g b / 25%);
    --rs-shadow-overlay: 0 4px 4px rgba(0, 0, 0, 0.12), 0 0 10px rgba(0, 0, 0, 0.06);
    --rs-btn-default-bg: var(--rs-gray-600);
    --rs-btn-default-text: var(--rs-gray-50);
    --rs-btn-default-hover-bg: var(--rs-gray-500);
    --rs-btn-default-active-bg: var(--rs-gray-300);
    --rs-btn-default-active-text: var(--rs-gray-0);
    --rs-btn-default-disabled-bg: var(--rs-gray-600);
    --rs-btn-default-disabled-text: var(--rs-gray-400);
    --rs-btn-primary-bg: var(--rs-primary-700);
    --rs-btn-primary-text: var(--rs-gray-0);
    --rs-btn-primary-hover-bg: var(--rs-primary-600);
    --rs-btn-primary-active-bg: var(--rs-primary-400);
    --rs-btn-subtle-text: var(--rs-gray-200);
    --rs-btn-subtle-hover-bg: var(--rs-gray-500);
    --rs-btn-subtle-hover-text: var(--rs-gray-50);
    --rs-btn-subtle-active-bg: var(--rs-gray-400);
    --rs-btn-subtle-active-text: var(--rs-gray-0);
    --rs-btn-subtle-disabled-text: var(--rs-gray-500);
    --rs-btn-ghost-border: var(--rs-primary-500);
    --rs-btn-ghost-text: var(--rs-primary-500);
    --rs-btn-ghost-hover-border: var(--rs-primary-400);
    --rs-btn-ghost-hover-text: var(--rs-primary-400);
    --rs-btn-ghost-active-border: var(--rs-primary-200);
    --rs-btn-ghost-active-text: var(--rs-primary-200);
    --rs-btn-link-text: var(--rs-primary-500);
    --rs-btn-link-hover-text: var(--rs-primary-400);
    --rs-btn-link-active-text: var(--rs-primary-200);
    --rs-loader-ring: rgb(from var(--rs-gray-50) r g b / 30%);
    --rs-loader-rotor: var(--rs-gray-0);
    --rs-loader-backdrop: rgb(from var(--rs-gray-900) r g b / 83%);
    --rs-loader-ring-inverse: rgb(from var(--rs-gray-50) r g b / 80%);
    --rs-loader-rotor-inverse: var(--rs-gray-500);
    --rs-loader-backdrop-inverse: rgb(from var(--rs-gray-0) r g b / 90%);
    --rs-input-bg: var(--rs-gray-800);
    --rs-input-focus-border: var(--rs-primary-500);
    --rs-input-disabled-bg: var(--rs-gray-700);
    --rs-listbox-option-group-bg: var(--rs-gray-700);
    --rs-listbox-option-hover-bg: var(--rs-gray-600);
    --rs-listbox-option-hover-text: currentColor;
    --rs-checkbox-icon: var(--rs-gray-800);
    --rs-checkbox-border: var(--rs-gray-400);
    --rs-checkbox-checked-bg: var(--rs-primary-500);
    --rs-checkbox-disabled-bg: var(--rs-gray-500);
    --rs-close-button-hover-color: var(--rs-color-red);
    --rs-picker-value: var(--rs-primary-500);
    --rs-picker-count-bg: var(--rs-primary-700);
    --rs-picker-count-text: #fff;
}
@supports not (color: rgb(from white r g b)) {
    .rs-theme-dark {
        --rs-color-focus-ring: 0 0 0 3px rgba(52, 195, 255, 0.25);
        --rs-state-focus-shadow: 0 0 0 3px rgba(52, 195, 255, 0.25);
        --rs-loader-ring: rgba(233, 235, 240, 0.3);
        --rs-loader-backdrop: rgba(15, 19, 26, 0.83);
        --rs-loader-ring-inverse: rgba(233, 235, 240, 0.8);
        --rs-loader-backdrop-inverse: rgba(255, 255, 255, 0.9);
    }
}
.rs-theme-high-contrast {
    --rs-gray-0: #fff;
    --rs-gray-50: #e9ebf0;
    --rs-gray-100: #cbced4;
    --rs-gray-200: #a4a9b3;
    --rs-gray-300: #858b94;
    --rs-gray-400: #6a6f76;
    --rs-gray-500: #5c6066;
    --rs-gray-600: #3c3f43;
    --rs-gray-700: #292d33;
    --rs-gray-800: #1a1d24;
    --rs-gray-900: #0f131a;
    --rs-primary-100: #fffbc2;
    --rs-primary-200: #fffa91;
    --rs-primary-400: #fffc30;
    --rs-primary-500: #ffff00;
    --rs-primary-600: #f1f500;
    --rs-primary-700: #d9e000;
    --rs-primary-800: #b8c200;
    --rs-primary-900: #8f9900;
    --rs-color-red: #bd1732;
    --rs-red-200: #e691a3;
    --rs-red-400: #c93a55;
    --rs-red-500: #bd1732;
    --rs-red-600: #b51029;
    --rs-red-700: #a60a1f;
    --rs-red-800: #8f0414;
    --rs-red-900: #70000b;
    --rs-orange-200: #ffce91;
    --rs-orange-400: #ffa930;
    --rs-orange-500: #ff9800;
    --rs-orange-600: #f59700;
    --rs-orange-700: #e08e00;
    --rs-orange-800: #c27e00;
    --rs-orange-900: #996600;
    --rs-yellow-200: #ffe2b3;
    --rs-yellow-400: #ffcf75;
    --rs-yellow-500: #ffc757;
    --rs-yellow-600: #f5bb3d;
    --rs-yellow-700: #e0a824;
    --rs-yellow-800: #c28f0e;
    --rs-yellow-900: #997000;
    --rs-green-200: #7ccc8e;
    --rs-green-400: #2a9c46;
    --rs-green-500: #0d822c;
    --rs-green-600: #09802b;
    --rs-green-700: #057a28;
    --rs-green-800: #017325;
    --rs-green-900: #006923;
    --rs-cyan-200: #87e6ed;
    --rs-cyan-400: #2acadb;
    --rs-cyan-500: #00bcd4;
    --rs-cyan-600: #00b1cc;
    --rs-cyan-700: #00a0bd;
    --rs-cyan-800: #008aa6;
    --rs-cyan-900: #006e87;
    --rs-blue-200: #93d6fa;
    --rs-blue-400: #3dadf2;
    --rs-blue-500: #1499ef;
    --rs-blue-600: #0e8ce6;
    --rs-blue-700: #087ad1;
    --rs-blue-800: #0464b3;
    --rs-blue-900: #00498a;
    --rs-violet-200: #b6a1e3;
    --rs-violet-400: #805ac7;
    --rs-violet-500: #673ab7;
    --rs-violet-600: #5f2bb3;
    --rs-violet-700: #531ba8;
    --rs-violet-800: #470c99;
    --rs-violet-900: #390085;
    --rs-state-error: var(--rs-color-red);
    --rs-text-primary: var(--rs-gray-50);
    --rs-text-secondary: var(--rs-gray-200);
    --rs-text-heading: var(--rs-gray-0);
    --rs-text-inverse: var(--rs-gray-800);
    --rs-text-disabled: var(--rs-gray-400);
    --rs-text-highlight-bg: var(--rs-primary-500);
    --rs-border-primary: var(--rs-gray-100);
    --rs-bg-overlay: var(--rs-gray-800);
    --rs-color-focus-ring: var(--rs-gray-0);
    --rs-state-focus-shadow: 0 0 0 3px var(--rs-gray-900), 0 0 0 5px var(--rs-gray-0);
    --rs-state-error-outline: 3px solid rgb(from var(--rs-color-red) r g b / 25%);
    --rs-shadow-overlay: 0 4px 4px rgba(0, 0, 0, 0.12), 0 0 10px rgba(0, 0, 0, 0.06);
    --rs-btn-default-bg: transparent;
    --rs-btn-default-text: var(--rs-primary-500);
    --rs-btn-default-border: 1px solid var(--rs-primary-500);
    --rs-btn-default-hover-bg: transparent;
    --rs-btn-default-hover-text: var(--rs-primary-400);
    --rs-btn-default-active-bg: transparent;
    --rs-btn-default-active-text: var(--rs-primary-200);
    --rs-btn-default-disabled-bg: transparent;
    --rs-btn-default-disabled-text: var(--rs-primary-900);
    --rs-btn-default-disabled-boreder-color: var(--rs-gray-100);
    --rs-btn-primary-bg: var(--rs-primary-500);
    --rs-btn-primary-text: var(--rs-gray-900);
    --rs-btn-primary-hover-bg: var(--rs-primary-400);
    --rs-btn-primary-active-bg: var(--rs-primary-200);
    --rs-btn-subtle-text: var(--rs-primary-500);
    --rs-btn-subtle-hover-bg: transparent;
    --rs-btn-subtle-hover-text: var(--rs-primary-400);
    --rs-btn-subtle-active-bg: transparent;
    --rs-btn-subtle-active-text: var(--rs-primary-200);
    --rs-btn-subtle-disabled-text: var(--rs-gray-500);
    --rs-btn-ghost-border: var(--rs-primary-500);
    --rs-btn-ghost-text: var(--rs-primary-500);
    --rs-btn-ghost-hover-border: var(--rs-primary-400);
    --rs-btn-ghost-hover-text: var(--rs-primary-400);
    --rs-btn-ghost-active-border: var(--rs-primary-200);
    --rs-btn-ghost-active-text: var(--rs-primary-200);
    --rs-btn-link-text: var(--rs-primary-500);
    --rs-btn-link-hover-text: var(--rs-primary-400);
    --rs-btn-link-active-text: var(--rs-primary-200);
    --rs-loader-ring: rgb(from var(--rs-gray-50) r g b / 30%);
    --rs-loader-rotor: var(--rs-gray-0);
    --rs-loader-backdrop: rgb(from var(--rs-gray-900) r g b / 83%);
    --rs-loader-ring-inverse: rgb(from var(--rs-gray-50) r g b / 80%);
    --rs-loader-rotor-inverse: var(--rs-gray-500);
    --rs-loader-backdrop-inverse: rgb(from var(--rs-gray-0) r g b / 90%);
    --rs-input-bg: var(--rs-gray-800);
    --rs-input-focus-border: var(--rs-primary-500);
    --rs-input-disabled-bg: var(--rs-gray-700);
    --rs-listbox-option-group-bg: var(--rs-gray-800);
    --rs-listbox-option-hover-bg: transparent;
    --rs-listbox-option-hover-text: var(--rs-primary-500);
    --rs-checkbox-icon: var(--rs-gray-800);
    --rs-checkbox-border: var(--rs-gray-100);
    --rs-checkbox-checked-bg: var(--rs-primary-500);
    --rs-checkbox-disabled-bg: var(--rs-gray-500);
    --rs-close-button-hover-color: var(--rs-color-red);
    --rs-picker-value: var(--rs-primary-500);
    --rs-picker-count-bg: var(--rs-primary-500);
    --rs-picker-count-text: var(--rs-gray-900);
}
@supports not (color: rgb(from white r g b)) {
    .rs-theme-high-contrast {
        --rs-loader-ring: rgba(233, 235, 240, 0.3);
        --rs-loader-backdrop: rgba(15, 19, 26, 0.83);
        --rs-loader-ring-inverse: rgba(233, 235, 240, 0.8);
        --rs-loader-backdrop-inverse: rgba(255, 255, 255, 0.9);
    }
}
/* stylelint-disable */
*[class*='rs-'] {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}
*[class*='rs-']::before,
*[class*='rs-']::after {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}
.rs-checkbox {
    position: relative;
    display: inline-block;
    vertical-align: middle;
}
.rs-checkbox-disabled label {
    cursor: not-allowed;
}
.rs-checkbox label {
    line-height: 1.14285714;
    margin-bottom: 0;
    font-weight: normal;
    cursor: pointer;
}
.rs-checkbox-inner::before {
    border-color: #3498ff;
    border-color: var(--rs-checkbox-checked-bg);
}
.rs-checkbox.rs-checkbox-disabled label {
    cursor: not-allowed;
}
.rs-checkbox-disabled > .rs-checkbox-checker > label {
    color: #717273;
    color: var(--rs-text-disabled);
}
.rs-checkbox-inline {
    position: relative;
    display: inline-block;
    margin-bottom: 0;
    vertical-align: middle;
    font-weight: normal;
    margin-top: 0;
    margin-right: 10px;
}
.rs-plaintext .rs-checkbox-inline:first-child {
    margin-left: 0;
}
.rs-checkbox [type='checkbox']:focus-visible ~ .rs-checkbox-inner::before {
    -webkit-box-shadow: 0 0 0 3px rgb(from #3498ff r g b / 25%);
    -webkit-box-shadow: var(--rs-state-focus-shadow);
    box-shadow: 0 0 0 3px rgb(from #3498ff r g b / 25%);
    box-shadow: var(--rs-state-focus-shadow);
}
.rs-checkbox-checker {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 36px;
    min-height: 36px;
    line-height: 1;
    position: relative;
}
/* rtl:begin:ignore */
.rs-checkbox-control::before,
.rs-checkbox-control .rs-checkbox-inner::before,
.rs-checkbox-control .rs-checkbox-inner::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    display: block;
}
.rs-checkbox-control .rs-checkbox-inner::after {
    opacity: 0;
    -webkit-transform: rotate(45deg) scale(0);
    transform: rotate(45deg) scale(0);
    -webkit-transition: opacity 0.2s ease-in, -webkit-transform 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46);
    transition: opacity 0.2s ease-in, -webkit-transform 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46);
    transition: opacity 0.2s ease-in, transform 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46);
    transition: opacity 0.2s ease-in, transform 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46), -webkit-transform 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46);
}
.rs-theme-high-contrast .rs-checkbox-control .rs-checkbox-inner::after {
    -webkit-transition: none;
    transition: none;
}
.rs-checkbox-checked .rs-checkbox-control .rs-checkbox-inner::after,
.rs-checkbox-indeterminate .rs-checkbox-control .rs-checkbox-inner::after {
    border: solid #fff;
    border: solid var(--rs-checkbox-icon);
    width: 6px;
    height: 9px;
    margin-top: 2px;
    margin-left: 5px;
    opacity: 1;
}
.rs-checkbox-checked .rs-checkbox-control .rs-checkbox-inner::after {
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg) scale(1);
    transform: rotate(45deg) scale(1);
}
.rs-checkbox-indeterminate .rs-checkbox-control .rs-checkbox-inner::after {
    border-width: 0 0 2px;
    -webkit-transform: rotate(0deg) scale(1);
    transform: rotate(0deg) scale(1);
    width: 10px;
    margin-top: 0;
    margin-left: 3px;
}
/* rtl:end:ignore */
/* stylelint-disable-next-line */
.rs-checkbox-control {
    position: absolute;
    width: 16px;
    height: 16px;
    display: inline-block;
    left: 10px;
    top: 10px;
}
.rs-checkbox-control [type='checkbox'] {
    position: absolute;
    opacity: 0;
    z-index: 1;
    top: -10px;
    right: -10px;
    bottom: -10px;
    left: -10px;
    min-width: 36px;
    min-height: 36px;
}
.rs-checkbox-control::before,
.rs-checkbox-control .rs-checkbox-inner::before,
.rs-checkbox-control .rs-checkbox-inner::after {
    width: 16px;
    height: 16px;
}
.rs-checkbox-control::before {
    border: 1px solid #3498ff;
    border: 1px solid var(--rs-checkbox-checked-bg);
    background-color: transparent;
    border-radius: 3px;
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0.7;
    visibility: hidden;
    -webkit-transition: opacity 0.2s linear, -webkit-transform 0.2s linear;
    transition: opacity 0.2s linear, -webkit-transform 0.2s linear;
    transition: transform 0.2s linear, opacity 0.2s linear;
    transition: transform 0.2s linear, opacity 0.2s linear, -webkit-transform 0.2s linear;
}
.rs-theme-high-contrast .rs-checkbox-control::before {
    -webkit-transition: none;
    transition: none;
}
.rs-checkbox-checked .rs-checkbox-control::before {
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
    opacity: 0;
    visibility: visible;
}
.rs-checkbox-control .rs-checkbox-inner::before {
    border: 1px solid #d9d9d9;
    border: 1px solid var(--rs-checkbox-border);
    background-color: transparent;
    border-radius: 3px;
    -webkit-transition: background 0.2s linear, border 0.2s linear, -webkit-box-shadow 0.2s linear;
    transition: background 0.2s linear, border 0.2s linear, -webkit-box-shadow 0.2s linear;
    transition: background 0.2s linear, border 0.2s linear, box-shadow 0.2s linear;
    transition: background 0.2s linear, border 0.2s linear, box-shadow 0.2s linear, -webkit-box-shadow 0.2s linear;
}
.rs-theme-high-contrast .rs-checkbox-control .rs-checkbox-inner::before {
    -webkit-transition: none;
    transition: none;
}
label:hover .rs-checkbox-control .rs-checkbox-inner::before {
    border-color: #3498ff;
    border-color: var(--rs-checkbox-checked-bg);
}
.rs-checkbox-disabled:not(.rs-checkbox-checked):not(.rs-checkbox-indeterminate) .rs-checkbox-control .rs-checkbox-inner::before {
    border-color: #f7f7fa;
    border-color: var(--rs-checkbox-disabled-bg);
    background-color: #f7f7fa;
    background-color: var(--rs-checkbox-disabled-bg);
}
.rs-checkbox-checked .rs-checkbox-control .rs-checkbox-inner::before,
.rs-checkbox-indeterminate .rs-checkbox-control .rs-checkbox-inner::before {
    border-color: #3498ff;
    border-color: var(--rs-checkbox-checked-bg);
    background-color: #3498ff;
    background-color: var(--rs-checkbox-checked-bg);
}
.rs-checkbox-disabled.rs-checkbox-checked .rs-checkbox-control .rs-checkbox-inner::before,
.rs-checkbox-disabled.rs-checkbox-indeterminate .rs-checkbox-control .rs-checkbox-inner::before {
    opacity: 0.5;
}
.rs-checkbox-red .rs-checkbox-control::before {
    border-color: #f44336;
    border-color: var(--rs-red-500);
}
.rs-checkbox-red label:hover .rs-checkbox-inner::before {
    border-color: #f44336;
    border-color: var(--rs-red-500);
}
.rs-checkbox-red.rs-checkbox-checked .rs-checkbox-inner::before,
.rs-checkbox-red.rs-checkbox-indeterminate .rs-checkbox-inner::before {
    border-color: #f44336;
    border-color: var(--rs-red-500);
    background-color: #f44336;
    background-color: var(--rs-red-500);
}
.rs-checkbox-orange .rs-checkbox-control::before {
    border-color: #fa8900;
    border-color: var(--rs-orange-500);
}
.rs-checkbox-orange label:hover .rs-checkbox-inner::before {
    border-color: #fa8900;
    border-color: var(--rs-orange-500);
}
.rs-checkbox-orange.rs-checkbox-checked .rs-checkbox-inner::before,
.rs-checkbox-orange.rs-checkbox-indeterminate .rs-checkbox-inner::before {
    border-color: #fa8900;
    border-color: var(--rs-orange-500);
    background-color: #fa8900;
    background-color: var(--rs-orange-500);
}
.rs-checkbox-yellow .rs-checkbox-control::before {
    border-color: #ffb300;
    border-color: var(--rs-yellow-500);
}
.rs-checkbox-yellow label:hover .rs-checkbox-inner::before {
    border-color: #ffb300;
    border-color: var(--rs-yellow-500);
}
.rs-checkbox-yellow.rs-checkbox-checked .rs-checkbox-inner::before,
.rs-checkbox-yellow.rs-checkbox-indeterminate .rs-checkbox-inner::before {
    border-color: #ffb300;
    border-color: var(--rs-yellow-500);
    background-color: #ffb300;
    background-color: var(--rs-yellow-500);
}
.rs-checkbox-green .rs-checkbox-control::before {
    border-color: #4caf50;
    border-color: var(--rs-green-500);
}
.rs-checkbox-green label:hover .rs-checkbox-inner::before {
    border-color: #4caf50;
    border-color: var(--rs-green-500);
}
.rs-checkbox-green.rs-checkbox-checked .rs-checkbox-inner::before,
.rs-checkbox-green.rs-checkbox-indeterminate .rs-checkbox-inner::before {
    border-color: #4caf50;
    border-color: var(--rs-green-500);
    background-color: #4caf50;
    background-color: var(--rs-green-500);
}
.rs-checkbox-cyan .rs-checkbox-control::before {
    border-color: #00bcd4;
    border-color: var(--rs-cyan-500);
}
.rs-checkbox-cyan label:hover .rs-checkbox-inner::before {
    border-color: #00bcd4;
    border-color: var(--rs-cyan-500);
}
.rs-checkbox-cyan.rs-checkbox-checked .rs-checkbox-inner::before,
.rs-checkbox-cyan.rs-checkbox-indeterminate .rs-checkbox-inner::before {
    border-color: #00bcd4;
    border-color: var(--rs-cyan-500);
    background-color: #00bcd4;
    background-color: var(--rs-cyan-500);
}
.rs-checkbox-blue .rs-checkbox-control::before {
    border-color: #2196f3;
    border-color: var(--rs-blue-500);
}
.rs-checkbox-blue label:hover .rs-checkbox-inner::before {
    border-color: #2196f3;
    border-color: var(--rs-blue-500);
}
.rs-checkbox-blue.rs-checkbox-checked .rs-checkbox-inner::before,
.rs-checkbox-blue.rs-checkbox-indeterminate .rs-checkbox-inner::before {
    border-color: #2196f3;
    border-color: var(--rs-blue-500);
    background-color: #2196f3;
    background-color: var(--rs-blue-500);
}
.rs-checkbox-violet .rs-checkbox-control::before {
    border-color: #673ab7;
    border-color: var(--rs-violet-500);
}
.rs-checkbox-violet label:hover .rs-checkbox-inner::before {
    border-color: #673ab7;
    border-color: var(--rs-violet-500);
}
.rs-checkbox-violet.rs-checkbox-checked .rs-checkbox-inner::before,
.rs-checkbox-violet.rs-checkbox-indeterminate .rs-checkbox-inner::before {
    border-color: #673ab7;
    border-color: var(--rs-violet-500);
    background-color: #673ab7;
    background-color: var(--rs-violet-500);
}
.rs-btn {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-bottom: 0;
    font-weight: normal;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    white-space: nowrap;
    -webkit-transition: color 0.15s ease-out, background-color 0.15s ease-out;
    transition: color 0.15s ease-out, background-color 0.15s ease-out;
    border: none;
    border: var(--rs-btn-default-border, none);
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    text-decoration: none;
    color: #343434;
    color: var(--rs-btn-default-text);
    background-color: #f7f7fa;
    background-color: var(--rs-btn-default-bg);
    border-radius: 6px;
    font-size: 14px;
    line-height: 20px;
    padding: 8px 12px;
    overflow: hidden;
    position: relative;
}
.rs-theme-high-contrast .rs-btn {
    -webkit-transition: none;
    transition: none;
}
.rs-btn-ghost.rs-btn {
    padding: 7px 11px;
}
.rs-btn-icon.rs-btn {
    padding: 10px 10px;
    line-height: 16px;
}
.rs-btn-icon.rs-btn > .rs-icon {
    font-size: 16px;
}
.rs-btn-icon-with-text.rs-btn {
    line-height: 20px;
}
.rs-btn-icon-with-text.rs-btn > .rs-icon {
    padding: 10px 10px;
    width: 36px;
    height: 36px;
}
.rs-btn-icon-with-text.rs-btn.rs-btn-icon-placement-left {
    padding: 8px 12px 8px 48px;
}
.rs-btn-icon-with-text.rs-btn.rs-btn-icon-placement-left > .rs-icon {
    left: 0;
    border-right: none;
    border-right: var(--rs-btn-default-border, none);
}
.rs-btn-icon-with-text.rs-btn.rs-btn-icon-placement-right {
    padding: 8px 48px 8px 12px;
}
.rs-btn-icon-with-text.rs-btn.rs-btn-icon-placement-right > .rs-icon {
    right: 0;
    border-left: none;
    border-left: var(--rs-btn-default-border, none);
}
.rs-btn:focus-visible {
    outline: 3px solid rgb(from #3498ff r g b / 25%);
    outline: 3px solid var(--rs-color-focus-ring);
}
.rs-theme-high-contrast .rs-btn:focus-visible {
    outline-offset: 2px;
}
.rs-btn:hover,
.rs-btn:focus {
    color: var(--rs-btn-default-hover-text);
    background-color: #e5e5ea;
    background-color: var(--rs-btn-default-hover-bg);
    text-decoration: none;
}
.rs-btn:active,
.rs-btn.rs-btn-active {
    color: #121212;
    color: var(--rs-btn-default-active-text);
    background-color: #d9d9d9;
    background-color: var(--rs-btn-default-active-bg);
}
.rs-btn:disabled,
.rs-btn.rs-btn-disabled {
    cursor: not-allowed;
    color: #717273;
    color: var(--rs-btn-default-disabled-text);
    background-color: #f7f7fa;
    background-color: var(--rs-btn-default-disabled-bg);
}
.rs-theme-high-contrast .rs-btn:disabled,
.rs-theme-high-contrast .rs-btn.rs-btn-disabled {
    border-color: var(--rs-btn-default-disabled-boreder-color);
}
@media not all and (min-resolution: 0.001dpcm) {
    .rs-btn {
        /* stylelint-disable */
        -webkit-mask-image: -webkit-radial-gradient(white, black);
        /* stylelint-enable */
    }
}
.rs-btn-start-icon {
    line-height: 0;
    margin-right: 5px;
}
.rs-btn-end-icon {
    line-height: 0;
    margin-left: 5px;
}
.rs-btn-primary {
    color: #fff;
    color: var(--rs-btn-primary-text);
    background-color: #3498ff;
    background-color: var(--rs-btn-primary-bg);
    border: none;
}
.rs-btn-primary:hover,
.rs-btn-primary:focus {
    color: #fff;
    color: var(--rs-btn-primary-text);
    background-color: #2589f5;
    background-color: var(--rs-btn-primary-hover-bg);
}
.rs-btn-primary:active,
.rs-btn-primary.rs-btn-active {
    color: #fff;
    color: var(--rs-btn-primary-text);
    background-color: #1675e0;
    background-color: var(--rs-btn-primary-active-bg);
}
.rs-btn-primary:disabled,
.rs-btn-primary.rs-btn-disabled {
    color: #fff;
    color: var(--rs-btn-primary-text);
    background-color: #3498ff;
    background-color: var(--rs-btn-primary-bg);
    opacity: 0.3;
}
.rs-theme-high-contrast .rs-btn-primary:disabled,
.rs-theme-high-contrast .rs-btn-primary.rs-btn-disabled {
    border-color: var(--rs-btn-default-disabled-boreder-color);
}
.rs-btn-subtle {
    color: #343434;
    color: var(--rs-btn-subtle-text);
    background-color: transparent;
    border: none;
}
.rs-btn-subtle:hover,
.rs-btn-subtle:focus {
    color: #343434;
    color: var(--rs-btn-subtle-hover-text);
    background-color: #e5e5ea;
    background-color: var(--rs-btn-subtle-hover-bg);
}
.rs-btn-subtle:active,
.rs-btn-subtle.rs-btn-active {
    color: #121212;
    color: var(--rs-btn-subtle-active-text);
    background-color: #e5e5ea;
    background-color: var(--rs-btn-subtle-active-bg);
}
.rs-btn-subtle:disabled,
.rs-btn-subtle.rs-btn-disabled {
    color: #b6b7b8;
    color: var(--rs-btn-subtle-disabled-text);
    background: none;
}
.rs-theme-high-contrast .rs-btn-subtle:disabled,
.rs-theme-high-contrast .rs-btn-subtle.rs-btn-disabled {
    border-color: var(--rs-btn-default-disabled-boreder-color);
}
.rs-btn-link {
    color: #1675e0;
    color: var(--rs-btn-link-text);
    background-color: transparent;
    border: none;
}
.rs-btn-link:hover,
.rs-btn-link:focus {
    color: #0a5dc2;
    color: var(--rs-btn-link-hover-text);
    background-color: transparent;
    text-decoration: underline;
}
.rs-btn-link:active,
.rs-btn-link.rs-btn-active {
    color: #004299;
    color: var(--rs-btn-link-active-text);
    background-color: transparent;
}
.rs-btn-link:disabled,
.rs-btn-link.rs-btn-disabled {
    color: #0a5dc2;
    color: var(--rs-btn-link-hover-text);
    background-color: transparent;
    text-decoration: none;
    opacity: 0.3;
}
.rs-theme-high-contrast .rs-btn-link:disabled,
.rs-theme-high-contrast .rs-btn-link.rs-btn-disabled {
    border-color: var(--rs-btn-default-disabled-boreder-color);
}
.rs-btn-ghost {
    color: #1675e0;
    color: var(--rs-btn-ghost-text);
    background-color: transparent;
    border: 1px solid #1675e0;
    border: 1px solid var(--rs-btn-ghost-border);
}
.rs-btn-ghost:hover,
.rs-btn-ghost:focus {
    color: #0a5dc2;
    color: var(--rs-btn-ghost-hover-text);
    background-color: transparent;
    border-color: #0a5dc2;
    border-color: var(--rs-btn-ghost-hover-border);
    -webkit-box-shadow: 0 0 0 1px #0a5dc2;
    -webkit-box-shadow: 0 0 0 1px var(--rs-btn-ghost-hover-border);
    box-shadow: 0 0 0 1px #0a5dc2;
    box-shadow: 0 0 0 1px var(--rs-btn-ghost-hover-border);
}
.rs-btn-ghost:active,
.rs-btn-ghost.rs-btn-active {
    color: #004299;
    color: var(--rs-btn-ghost-active-text);
    background-color: transparent;
    border-color: #004299;
    border-color: var(--rs-btn-ghost-active-border);
}
.rs-btn-ghost:disabled,
.rs-btn-ghost.rs-btn-disabled {
    color: #1675e0;
    color: var(--rs-btn-ghost-text);
    background-color: transparent;
    opacity: 0.3;
    border-color: #1675e0;
    border-color: var(--rs-btn-ghost-border);
    -webkit-box-shadow: none;
    box-shadow: none;
}
.rs-theme-high-contrast .rs-btn-ghost:disabled,
.rs-theme-high-contrast .rs-btn-ghost.rs-btn-disabled {
    border-color: var(--rs-btn-default-disabled-boreder-color);
}
.rs-btn-red {
    --rs-btn-primary-bg: var(--rs-red-500);
    --rs-btn-primary-hover-bg: var(--rs-red-700);
    --rs-btn-primary-active-bg: var(--rs-red-800);
    --rs-btn-subtle-hover-bg: var(--rs-red-500);
    --rs-btn-subtle-hover-text: #e5e5ea;
    --rs-btn-subtle-active-bg: var(--rs-red-600);
    --rs-btn-subtle-active-text: #343434;
    --rs-btn-ghost-border: var(--rs-red-700);
    --rs-btn-ghost-text: var(--rs-red-700);
    --rs-btn-ghost-hover-border: var(--rs-red-900);
    --rs-btn-ghost-hover-text: var(--rs-red-800);
    --rs-btn-ghost-active-border: var(--rs-red-900);
    --rs-btn-ghost-active-text: var(--rs-red-900);
    --rs-btn-link-text: var(--rs-red-700);
    --rs-btn-link-hover-text: var(--rs-red-800);
    --rs-btn-link-active-text: var(--rs-red-900);
}
.rs-theme-dark .rs-btn-red {
    --rs-btn-primary-bg: var(--rs-red-700);
    --rs-btn-primary-hover-bg: var(--rs-red-500);
    --rs-btn-primary-active-bg: var(--rs-red-400);
    --rs-btn-subtle-hover-bg: var(--rs-red-600);
    --rs-btn-subtle-hover-text: #fff;
    --rs-btn-subtle-active-bg: var(--rs-red-400);
    --rs-btn-subtle-active-text: #fff;
    --rs-btn-ghost-border: var(--rs-red-500);
    --rs-btn-ghost-text: var(--rs-red-500);
    --rs-btn-ghost-hover-border: var(--rs-red-400);
    --rs-btn-ghost-hover-text: var(--rs-red-400);
    --rs-btn-ghost-active-border: var(--rs-red-200);
    --rs-btn-ghost-active-text: var(--rs-red-200);
    --rs-btn-link-text: var(--rs-red-500);
    --rs-btn-link-hover-text: var(--rs-red-400);
    --rs-btn-link-active-text: var(--rs-red-200);
}
.rs-theme-high-contrast .rs-btn-red {
    --rs-btn-primary-bg: var(--rs-red-700);
    --rs-btn-primary-hover-bg: var(--rs-red-600);
    --rs-btn-primary-active-bg: var(--rs-red-400);
    --rs-btn-subtle-hover-bg: var(--rs-red-600);
    --rs-btn-subtle-hover-text: var(--rs-gray-900);
    --rs-btn-subtle-active-bg: var(--rs-red-400);
    --rs-btn-subtle-active-text: var(--rs-gray-900);
    --rs-btn-ghost-border: var(--rs-red-500);
    --rs-btn-ghost-text: var(--rs-red-500);
    --rs-btn-ghost-hover-border: var(--rs-red-400);
    --rs-btn-ghost-hover-text: var(--rs-red-400);
    --rs-btn-ghost-active-border: var(--rs-red-200);
    --rs-btn-ghost-active-text: var(--rs-red-200);
    --rs-btn-link-text: var(--rs-red-500);
    --rs-btn-link-hover-text: var(--rs-red-400);
    --rs-btn-link-active-text: var(--rs-red-200);
}
.rs-btn-orange {
    --rs-btn-primary-bg: var(--rs-orange-500);
    --rs-btn-primary-hover-bg: var(--rs-orange-700);
    --rs-btn-primary-active-bg: var(--rs-orange-800);
    --rs-btn-subtle-hover-bg: var(--rs-orange-500);
    --rs-btn-subtle-hover-text: #e5e5ea;
    --rs-btn-subtle-active-bg: var(--rs-orange-600);
    --rs-btn-subtle-active-text: #343434;
    --rs-btn-ghost-border: var(--rs-orange-700);
    --rs-btn-ghost-text: var(--rs-orange-700);
    --rs-btn-ghost-hover-border: var(--rs-orange-900);
    --rs-btn-ghost-hover-text: var(--rs-orange-800);
    --rs-btn-ghost-active-border: var(--rs-orange-900);
    --rs-btn-ghost-active-text: var(--rs-orange-900);
    --rs-btn-link-text: var(--rs-orange-700);
    --rs-btn-link-hover-text: var(--rs-orange-800);
    --rs-btn-link-active-text: var(--rs-orange-900);
}
.rs-theme-dark .rs-btn-orange {
    --rs-btn-primary-bg: var(--rs-orange-700);
    --rs-btn-primary-hover-bg: var(--rs-orange-500);
    --rs-btn-primary-active-bg: var(--rs-orange-400);
    --rs-btn-subtle-hover-bg: var(--rs-orange-600);
    --rs-btn-subtle-hover-text: #fff;
    --rs-btn-subtle-active-bg: var(--rs-orange-400);
    --rs-btn-subtle-active-text: #fff;
    --rs-btn-ghost-border: var(--rs-orange-500);
    --rs-btn-ghost-text: var(--rs-orange-500);
    --rs-btn-ghost-hover-border: var(--rs-orange-400);
    --rs-btn-ghost-hover-text: var(--rs-orange-400);
    --rs-btn-ghost-active-border: var(--rs-orange-200);
    --rs-btn-ghost-active-text: var(--rs-orange-200);
    --rs-btn-link-text: var(--rs-orange-500);
    --rs-btn-link-hover-text: var(--rs-orange-400);
    --rs-btn-link-active-text: var(--rs-orange-200);
}
.rs-theme-high-contrast .rs-btn-orange {
    --rs-btn-primary-bg: var(--rs-orange-700);
    --rs-btn-primary-hover-bg: var(--rs-orange-600);
    --rs-btn-primary-active-bg: var(--rs-orange-400);
    --rs-btn-subtle-hover-bg: var(--rs-orange-600);
    --rs-btn-subtle-hover-text: var(--rs-gray-900);
    --rs-btn-subtle-active-bg: var(--rs-orange-400);
    --rs-btn-subtle-active-text: var(--rs-gray-900);
    --rs-btn-ghost-border: var(--rs-orange-500);
    --rs-btn-ghost-text: var(--rs-orange-500);
    --rs-btn-ghost-hover-border: var(--rs-orange-400);
    --rs-btn-ghost-hover-text: var(--rs-orange-400);
    --rs-btn-ghost-active-border: var(--rs-orange-200);
    --rs-btn-ghost-active-text: var(--rs-orange-200);
    --rs-btn-link-text: var(--rs-orange-500);
    --rs-btn-link-hover-text: var(--rs-orange-400);
    --rs-btn-link-active-text: var(--rs-orange-200);
}
.rs-btn-yellow {
    --rs-btn-primary-bg: var(--rs-yellow-500);
    --rs-btn-primary-hover-bg: var(--rs-yellow-700);
    --rs-btn-primary-active-bg: var(--rs-yellow-800);
    --rs-btn-subtle-hover-bg: var(--rs-yellow-500);
    --rs-btn-subtle-hover-text: #e5e5ea;
    --rs-btn-subtle-active-bg: var(--rs-yellow-600);
    --rs-btn-subtle-active-text: #343434;
    --rs-btn-ghost-border: var(--rs-yellow-700);
    --rs-btn-ghost-text: var(--rs-yellow-700);
    --rs-btn-ghost-hover-border: var(--rs-yellow-900);
    --rs-btn-ghost-hover-text: var(--rs-yellow-800);
    --rs-btn-ghost-active-border: var(--rs-yellow-900);
    --rs-btn-ghost-active-text: var(--rs-yellow-900);
    --rs-btn-link-text: var(--rs-yellow-700);
    --rs-btn-link-hover-text: var(--rs-yellow-800);
    --rs-btn-link-active-text: var(--rs-yellow-900);
}
.rs-theme-dark .rs-btn-yellow {
    --rs-btn-primary-bg: var(--rs-yellow-700);
    --rs-btn-primary-hover-bg: var(--rs-yellow-500);
    --rs-btn-primary-active-bg: var(--rs-yellow-400);
    --rs-btn-subtle-hover-bg: var(--rs-yellow-600);
    --rs-btn-subtle-hover-text: #fff;
    --rs-btn-subtle-active-bg: var(--rs-yellow-400);
    --rs-btn-subtle-active-text: #fff;
    --rs-btn-ghost-border: var(--rs-yellow-500);
    --rs-btn-ghost-text: var(--rs-yellow-500);
    --rs-btn-ghost-hover-border: var(--rs-yellow-400);
    --rs-btn-ghost-hover-text: var(--rs-yellow-400);
    --rs-btn-ghost-active-border: var(--rs-yellow-200);
    --rs-btn-ghost-active-text: var(--rs-yellow-200);
    --rs-btn-link-text: var(--rs-yellow-500);
    --rs-btn-link-hover-text: var(--rs-yellow-400);
    --rs-btn-link-active-text: var(--rs-yellow-200);
}
.rs-theme-high-contrast .rs-btn-yellow {
    --rs-btn-primary-bg: var(--rs-yellow-700);
    --rs-btn-primary-hover-bg: var(--rs-yellow-600);
    --rs-btn-primary-active-bg: var(--rs-yellow-400);
    --rs-btn-subtle-hover-bg: var(--rs-yellow-600);
    --rs-btn-subtle-hover-text: var(--rs-gray-900);
    --rs-btn-subtle-active-bg: var(--rs-yellow-400);
    --rs-btn-subtle-active-text: var(--rs-gray-900);
    --rs-btn-ghost-border: var(--rs-yellow-500);
    --rs-btn-ghost-text: var(--rs-yellow-500);
    --rs-btn-ghost-hover-border: var(--rs-yellow-400);
    --rs-btn-ghost-hover-text: var(--rs-yellow-400);
    --rs-btn-ghost-active-border: var(--rs-yellow-200);
    --rs-btn-ghost-active-text: var(--rs-yellow-200);
    --rs-btn-link-text: var(--rs-yellow-500);
    --rs-btn-link-hover-text: var(--rs-yellow-400);
    --rs-btn-link-active-text: var(--rs-yellow-200);
}
.rs-btn-green {
    --rs-btn-primary-bg: var(--rs-green-500);
    --rs-btn-primary-hover-bg: var(--rs-green-700);
    --rs-btn-primary-active-bg: var(--rs-green-800);
    --rs-btn-subtle-hover-bg: var(--rs-green-500);
    --rs-btn-subtle-hover-text: #e5e5ea;
    --rs-btn-subtle-active-bg: var(--rs-green-600);
    --rs-btn-subtle-active-text: #343434;
    --rs-btn-ghost-border: var(--rs-green-700);
    --rs-btn-ghost-text: var(--rs-green-700);
    --rs-btn-ghost-hover-border: var(--rs-green-900);
    --rs-btn-ghost-hover-text: var(--rs-green-800);
    --rs-btn-ghost-active-border: var(--rs-green-900);
    --rs-btn-ghost-active-text: var(--rs-green-900);
    --rs-btn-link-text: var(--rs-green-700);
    --rs-btn-link-hover-text: var(--rs-green-800);
    --rs-btn-link-active-text: var(--rs-green-900);
}
.rs-theme-dark .rs-btn-green {
    --rs-btn-primary-bg: var(--rs-green-700);
    --rs-btn-primary-hover-bg: var(--rs-green-500);
    --rs-btn-primary-active-bg: var(--rs-green-400);
    --rs-btn-subtle-hover-bg: var(--rs-green-600);
    --rs-btn-subtle-hover-text: #fff;
    --rs-btn-subtle-active-bg: var(--rs-green-400);
    --rs-btn-subtle-active-text: #fff;
    --rs-btn-ghost-border: var(--rs-green-500);
    --rs-btn-ghost-text: var(--rs-green-500);
    --rs-btn-ghost-hover-border: var(--rs-green-400);
    --rs-btn-ghost-hover-text: var(--rs-green-400);
    --rs-btn-ghost-active-border: var(--rs-green-200);
    --rs-btn-ghost-active-text: var(--rs-green-200);
    --rs-btn-link-text: var(--rs-green-500);
    --rs-btn-link-hover-text: var(--rs-green-400);
    --rs-btn-link-active-text: var(--rs-green-200);
}
.rs-theme-high-contrast .rs-btn-green {
    --rs-btn-primary-bg: var(--rs-green-700);
    --rs-btn-primary-hover-bg: var(--rs-green-600);
    --rs-btn-primary-active-bg: var(--rs-green-400);
    --rs-btn-subtle-hover-bg: var(--rs-green-600);
    --rs-btn-subtle-hover-text: var(--rs-gray-900);
    --rs-btn-subtle-active-bg: var(--rs-green-400);
    --rs-btn-subtle-active-text: var(--rs-gray-900);
    --rs-btn-ghost-border: var(--rs-green-500);
    --rs-btn-ghost-text: var(--rs-green-500);
    --rs-btn-ghost-hover-border: var(--rs-green-400);
    --rs-btn-ghost-hover-text: var(--rs-green-400);
    --rs-btn-ghost-active-border: var(--rs-green-200);
    --rs-btn-ghost-active-text: var(--rs-green-200);
    --rs-btn-link-text: var(--rs-green-500);
    --rs-btn-link-hover-text: var(--rs-green-400);
    --rs-btn-link-active-text: var(--rs-green-200);
}
.rs-btn-cyan {
    --rs-btn-primary-bg: var(--rs-cyan-500);
    --rs-btn-primary-hover-bg: var(--rs-cyan-700);
    --rs-btn-primary-active-bg: var(--rs-cyan-800);
    --rs-btn-subtle-hover-bg: var(--rs-cyan-500);
    --rs-btn-subtle-hover-text: #e5e5ea;
    --rs-btn-subtle-active-bg: var(--rs-cyan-600);
    --rs-btn-subtle-active-text: #343434;
    --rs-btn-ghost-border: var(--rs-cyan-700);
    --rs-btn-ghost-text: var(--rs-cyan-700);
    --rs-btn-ghost-hover-border: var(--rs-cyan-900);
    --rs-btn-ghost-hover-text: var(--rs-cyan-800);
    --rs-btn-ghost-active-border: var(--rs-cyan-900);
    --rs-btn-ghost-active-text: var(--rs-cyan-900);
    --rs-btn-link-text: var(--rs-cyan-700);
    --rs-btn-link-hover-text: var(--rs-cyan-800);
    --rs-btn-link-active-text: var(--rs-cyan-900);
}
.rs-theme-dark .rs-btn-cyan {
    --rs-btn-primary-bg: var(--rs-cyan-700);
    --rs-btn-primary-hover-bg: var(--rs-cyan-500);
    --rs-btn-primary-active-bg: var(--rs-cyan-400);
    --rs-btn-subtle-hover-bg: var(--rs-cyan-600);
    --rs-btn-subtle-hover-text: #fff;
    --rs-btn-subtle-active-bg: var(--rs-cyan-400);
    --rs-btn-subtle-active-text: #fff;
    --rs-btn-ghost-border: var(--rs-cyan-500);
    --rs-btn-ghost-text: var(--rs-cyan-500);
    --rs-btn-ghost-hover-border: var(--rs-cyan-400);
    --rs-btn-ghost-hover-text: var(--rs-cyan-400);
    --rs-btn-ghost-active-border: var(--rs-cyan-200);
    --rs-btn-ghost-active-text: var(--rs-cyan-200);
    --rs-btn-link-text: var(--rs-cyan-500);
    --rs-btn-link-hover-text: var(--rs-cyan-400);
    --rs-btn-link-active-text: var(--rs-cyan-200);
}
.rs-theme-high-contrast .rs-btn-cyan {
    --rs-btn-primary-bg: var(--rs-cyan-700);
    --rs-btn-primary-hover-bg: var(--rs-cyan-600);
    --rs-btn-primary-active-bg: var(--rs-cyan-400);
    --rs-btn-subtle-hover-bg: var(--rs-cyan-600);
    --rs-btn-subtle-hover-text: var(--rs-gray-900);
    --rs-btn-subtle-active-bg: var(--rs-cyan-400);
    --rs-btn-subtle-active-text: var(--rs-gray-900);
    --rs-btn-ghost-border: var(--rs-cyan-500);
    --rs-btn-ghost-text: var(--rs-cyan-500);
    --rs-btn-ghost-hover-border: var(--rs-cyan-400);
    --rs-btn-ghost-hover-text: var(--rs-cyan-400);
    --rs-btn-ghost-active-border: var(--rs-cyan-200);
    --rs-btn-ghost-active-text: var(--rs-cyan-200);
    --rs-btn-link-text: var(--rs-cyan-500);
    --rs-btn-link-hover-text: var(--rs-cyan-400);
    --rs-btn-link-active-text: var(--rs-cyan-200);
}
.rs-btn-blue {
    --rs-btn-primary-bg: var(--rs-blue-500);
    --rs-btn-primary-hover-bg: var(--rs-blue-700);
    --rs-btn-primary-active-bg: var(--rs-blue-800);
    --rs-btn-subtle-hover-bg: var(--rs-blue-500);
    --rs-btn-subtle-hover-text: #e5e5ea;
    --rs-btn-subtle-active-bg: var(--rs-blue-600);
    --rs-btn-subtle-active-text: #343434;
    --rs-btn-ghost-border: var(--rs-blue-700);
    --rs-btn-ghost-text: var(--rs-blue-700);
    --rs-btn-ghost-hover-border: var(--rs-blue-900);
    --rs-btn-ghost-hover-text: var(--rs-blue-800);
    --rs-btn-ghost-active-border: var(--rs-blue-900);
    --rs-btn-ghost-active-text: var(--rs-blue-900);
    --rs-btn-link-text: var(--rs-blue-700);
    --rs-btn-link-hover-text: var(--rs-blue-800);
    --rs-btn-link-active-text: var(--rs-blue-900);
}
.rs-theme-dark .rs-btn-blue {
    --rs-btn-primary-bg: var(--rs-blue-700);
    --rs-btn-primary-hover-bg: var(--rs-blue-500);
    --rs-btn-primary-active-bg: var(--rs-blue-400);
    --rs-btn-subtle-hover-bg: var(--rs-blue-600);
    --rs-btn-subtle-hover-text: #fff;
    --rs-btn-subtle-active-bg: var(--rs-blue-400);
    --rs-btn-subtle-active-text: #fff;
    --rs-btn-ghost-border: var(--rs-blue-500);
    --rs-btn-ghost-text: var(--rs-blue-500);
    --rs-btn-ghost-hover-border: var(--rs-blue-400);
    --rs-btn-ghost-hover-text: var(--rs-blue-400);
    --rs-btn-ghost-active-border: var(--rs-blue-200);
    --rs-btn-ghost-active-text: var(--rs-blue-200);
    --rs-btn-link-text: var(--rs-blue-500);
    --rs-btn-link-hover-text: var(--rs-blue-400);
    --rs-btn-link-active-text: var(--rs-blue-200);
}
.rs-theme-high-contrast .rs-btn-blue {
    --rs-btn-primary-bg: var(--rs-blue-700);
    --rs-btn-primary-hover-bg: var(--rs-blue-600);
    --rs-btn-primary-active-bg: var(--rs-blue-400);
    --rs-btn-subtle-hover-bg: var(--rs-blue-600);
    --rs-btn-subtle-hover-text: var(--rs-gray-900);
    --rs-btn-subtle-active-bg: var(--rs-blue-400);
    --rs-btn-subtle-active-text: var(--rs-gray-900);
    --rs-btn-ghost-border: var(--rs-blue-500);
    --rs-btn-ghost-text: var(--rs-blue-500);
    --rs-btn-ghost-hover-border: var(--rs-blue-400);
    --rs-btn-ghost-hover-text: var(--rs-blue-400);
    --rs-btn-ghost-active-border: var(--rs-blue-200);
    --rs-btn-ghost-active-text: var(--rs-blue-200);
    --rs-btn-link-text: var(--rs-blue-500);
    --rs-btn-link-hover-text: var(--rs-blue-400);
    --rs-btn-link-active-text: var(--rs-blue-200);
}
.rs-btn-violet {
    --rs-btn-primary-bg: var(--rs-violet-500);
    --rs-btn-primary-hover-bg: var(--rs-violet-700);
    --rs-btn-primary-active-bg: var(--rs-violet-800);
    --rs-btn-subtle-hover-bg: var(--rs-violet-500);
    --rs-btn-subtle-hover-text: #e5e5ea;
    --rs-btn-subtle-active-bg: var(--rs-violet-600);
    --rs-btn-subtle-active-text: #343434;
    --rs-btn-ghost-border: var(--rs-violet-700);
    --rs-btn-ghost-text: var(--rs-violet-700);
    --rs-btn-ghost-hover-border: var(--rs-violet-900);
    --rs-btn-ghost-hover-text: var(--rs-violet-800);
    --rs-btn-ghost-active-border: var(--rs-violet-900);
    --rs-btn-ghost-active-text: var(--rs-violet-900);
    --rs-btn-link-text: var(--rs-violet-700);
    --rs-btn-link-hover-text: var(--rs-violet-800);
    --rs-btn-link-active-text: var(--rs-violet-900);
}
.rs-theme-dark .rs-btn-violet {
    --rs-btn-primary-bg: var(--rs-violet-700);
    --rs-btn-primary-hover-bg: var(--rs-violet-500);
    --rs-btn-primary-active-bg: var(--rs-violet-400);
    --rs-btn-subtle-hover-bg: var(--rs-violet-600);
    --rs-btn-subtle-hover-text: #fff;
    --rs-btn-subtle-active-bg: var(--rs-violet-400);
    --rs-btn-subtle-active-text: #fff;
    --rs-btn-ghost-border: var(--rs-violet-500);
    --rs-btn-ghost-text: var(--rs-violet-500);
    --rs-btn-ghost-hover-border: var(--rs-violet-400);
    --rs-btn-ghost-hover-text: var(--rs-violet-400);
    --rs-btn-ghost-active-border: var(--rs-violet-200);
    --rs-btn-ghost-active-text: var(--rs-violet-200);
    --rs-btn-link-text: var(--rs-violet-500);
    --rs-btn-link-hover-text: var(--rs-violet-400);
    --rs-btn-link-active-text: var(--rs-violet-200);
}
.rs-theme-high-contrast .rs-btn-violet {
    --rs-btn-primary-bg: var(--rs-violet-700);
    --rs-btn-primary-hover-bg: var(--rs-violet-600);
    --rs-btn-primary-active-bg: var(--rs-violet-400);
    --rs-btn-subtle-hover-bg: var(--rs-violet-600);
    --rs-btn-subtle-hover-text: var(--rs-gray-900);
    --rs-btn-subtle-active-bg: var(--rs-violet-400);
    --rs-btn-subtle-active-text: var(--rs-gray-900);
    --rs-btn-ghost-border: var(--rs-violet-500);
    --rs-btn-ghost-text: var(--rs-violet-500);
    --rs-btn-ghost-hover-border: var(--rs-violet-400);
    --rs-btn-ghost-hover-text: var(--rs-violet-400);
    --rs-btn-ghost-active-border: var(--rs-violet-200);
    --rs-btn-ghost-active-text: var(--rs-violet-200);
    --rs-btn-link-text: var(--rs-violet-500);
    --rs-btn-link-hover-text: var(--rs-violet-400);
    --rs-btn-link-active-text: var(--rs-violet-200);
}
.rs-btn-lg {
    font-size: 16px;
    line-height: 22px;
    padding: 10px 16px;
}
.rs-btn-ghost.rs-btn-lg {
    padding: 9px 15px;
}
.rs-btn-icon.rs-btn-lg {
    padding: 11px 11px;
    line-height: 20px;
}
.rs-btn-icon.rs-btn-lg > .rs-icon {
    font-size: 20px;
}
.rs-btn-icon-with-text.rs-btn-lg {
    line-height: 22px;
}
.rs-btn-icon-with-text.rs-btn-lg > .rs-icon {
    padding: 11px 11px;
    width: 42px;
    height: 42px;
}
.rs-btn-icon-with-text.rs-btn-lg.rs-btn-icon-placement-left {
    padding: 10px 16px 10px 58px;
}
.rs-btn-icon-with-text.rs-btn-lg.rs-btn-icon-placement-left > .rs-icon {
    left: 0;
    border-right: none;
    border-right: var(--rs-btn-default-border, none);
}
.rs-btn-icon-with-text.rs-btn-lg.rs-btn-icon-placement-right {
    padding: 10px 58px 10px 16px;
}
.rs-btn-icon-with-text.rs-btn-lg.rs-btn-icon-placement-right > .rs-icon {
    right: 0;
    border-left: none;
    border-left: var(--rs-btn-default-border, none);
}
.rs-btn-md {
    font-size: 14px;
    line-height: 20px;
    padding: 8px 12px;
}
.rs-btn-ghost.rs-btn-md {
    padding: 7px 11px;
}
.rs-btn-icon.rs-btn-md {
    padding: 10px 10px;
    line-height: 16px;
}
.rs-btn-icon.rs-btn-md > .rs-icon {
    font-size: 16px;
}
.rs-btn-icon-with-text.rs-btn-md {
    line-height: 20px;
}
.rs-btn-icon-with-text.rs-btn-md > .rs-icon {
    padding: 10px 10px;
    width: 36px;
    height: 36px;
}
.rs-btn-icon-with-text.rs-btn-md.rs-btn-icon-placement-left {
    padding: 8px 12px 8px 48px;
}
.rs-btn-icon-with-text.rs-btn-md.rs-btn-icon-placement-left > .rs-icon {
    left: 0;
    border-right: none;
    border-right: var(--rs-btn-default-border, none);
}
.rs-btn-icon-with-text.rs-btn-md.rs-btn-icon-placement-right {
    padding: 8px 48px 8px 12px;
}
.rs-btn-icon-with-text.rs-btn-md.rs-btn-icon-placement-right > .rs-icon {
    right: 0;
    border-left: none;
    border-left: var(--rs-btn-default-border, none);
}
.rs-btn-sm {
    font-size: 14px;
    line-height: 20px;
    padding: 5px 10px;
}
.rs-btn-ghost.rs-btn-sm {
    padding: 4px 9px;
}
.rs-btn-icon.rs-btn-sm {
    padding: 7px 7px;
    line-height: 16px;
}
.rs-btn-icon.rs-btn-sm > .rs-icon {
    font-size: 16px;
}
.rs-btn-icon-with-text.rs-btn-sm {
    line-height: 20px;
}
.rs-btn-icon-with-text.rs-btn-sm > .rs-icon {
    padding: 7px 7px;
    width: 30px;
    height: 30px;
}
.rs-btn-icon-with-text.rs-btn-sm.rs-btn-icon-placement-left {
    padding: 5px 10px 5px 40px;
}
.rs-btn-icon-with-text.rs-btn-sm.rs-btn-icon-placement-left > .rs-icon {
    left: 0;
    border-right: none;
    border-right: var(--rs-btn-default-border, none);
}
.rs-btn-icon-with-text.rs-btn-sm.rs-btn-icon-placement-right {
    padding: 5px 40px 5px 10px;
}
.rs-btn-icon-with-text.rs-btn-sm.rs-btn-icon-placement-right > .rs-icon {
    right: 0;
    border-left: none;
    border-left: var(--rs-btn-default-border, none);
}
.rs-btn-xs {
    font-size: 12px;
    line-height: 20px;
    padding: 2px 8px;
}
.rs-btn-ghost.rs-btn-xs {
    padding: 1px 7px;
}
.rs-btn-icon.rs-btn-xs {
    padding: 6px 6px;
    line-height: 12px;
}
.rs-btn-icon.rs-btn-xs > .rs-icon {
    font-size: 12px;
}
.rs-btn-icon-with-text.rs-btn-xs {
    line-height: 20px;
}
.rs-btn-icon-with-text.rs-btn-xs > .rs-icon {
    padding: 6px 6px;
    width: 24px;
    height: 24px;
}
.rs-btn-icon-with-text.rs-btn-xs.rs-btn-icon-placement-left {
    padding: 2px 8px 2px 32px;
}
.rs-btn-icon-with-text.rs-btn-xs.rs-btn-icon-placement-left > .rs-icon {
    left: 0;
    border-right: none;
    border-right: var(--rs-btn-default-border, none);
}
.rs-btn-icon-with-text.rs-btn-xs.rs-btn-icon-placement-right {
    padding: 2px 32px 2px 8px;
}
.rs-btn-icon-with-text.rs-btn-xs.rs-btn-icon-placement-right > .rs-icon {
    right: 0;
    border-left: none;
    border-left: var(--rs-btn-default-border, none);
}
.rs-btn-block {
    width: 100%;
}
.rs-btn-block + .rs-btn-block {
    margin-top: 5px;
}
.rs-btn-loading {
    color: transparent !important;
    position: relative;
    cursor: default;
    pointer-events: none;
}
.rs-btn-loading > .rs-btn-spin::before,
.rs-btn-loading > .rs-btn-spin::after {
    content: '';
    position: absolute;
    width: 18px;
    height: 18px;
    margin: auto;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: 50%;
    z-index: 1;
}
.rs-btn-xs.rs-btn-loading > .rs-btn-spin::before,
.rs-btn-xs.rs-btn-loading > .rs-btn-spin::after {
    width: 16px;
    height: 16px;
}
.rs-btn-loading > .rs-btn-spin::before {
    border: 3px solid rgb(from #f7f7fa r g b / 80%);
    border: 3px solid var(--rs-loader-ring);
}
.rs-btn-primary.rs-btn-loading > .rs-btn-spin::before {
    border-color: rgba(248, 247, 250, 0.3);
}
.rs-theme-high-contrast .rs-btn-primary.rs-btn-loading > .rs-btn-spin::before {
    border-color: rgb(from #f7f7fa r g b / 30%);
    border-color: var(--rs-loader-ring-inverse);
}
.rs-btn-loading > .rs-btn-spin::after {
    border-width: 3px;
    border-color: #939393 transparent transparent;
    border-color: var(--rs-loader-rotor) transparent transparent;
    border-style: solid;
    -webkit-animation: buttonSpin 0.6s infinite linear;
    animation: buttonSpin 0.6s infinite linear;
}
.rs-btn-primary.rs-btn-loading > .rs-btn-spin::after {
    border-top-color: #fff;
}
.rs-theme-high-contrast .rs-btn-primary.rs-btn-loading > .rs-btn-spin::after {
    border-top-color: #fff;
    border-top-color: var(--rs-loader-rotor-inverse);
}
@-webkit-keyframes buttonSpin {
    from {
        -webkit-transform: rotate(0);
        transform: rotate(0);
    }
    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@keyframes buttonSpin {
    from {
        -webkit-transform: rotate(0);
        transform: rotate(0);
    }
    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
.rs-stack {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}
.rs-btn-close {
    border: none;
    outline: none !important;
    background: transparent;
    line-height: 1;
    cursor: pointer;
}
.rs-btn-close .rs-icon {
    vertical-align: bottom;
}
.rs-btn-close:hover,
.rs-btn-close:focus {
    color: #f44336;
    color: var(--rs-close-button-hover-color);
}
.rs-btn-close:hover svg path,
.rs-btn-close:focus svg path {
    stroke: #f44336;
    stroke: var(--rs-close-button-hover-color);
    stroke-width: 1;
}
.rs-loader {
    display: inline-block;
}
.rs-loader::before,
.rs-loader::after {
    content: ' ';
    display: table;
}
.rs-loader::after {
    clear: both;
}
.rs-loader .rs-loader-spin {
    width: 18px;
    height: 18px;
}
.rs-loader .rs-loader-spin::before,
.rs-loader .rs-loader-spin::after {
    width: 18px;
    height: 18px;
}
.rs-loader .rs-loader-content {
    font-size: 12px;
    line-height: 1.5;
}
.rs-loader .rs-loader-vertical .rs-loader .rs-loader {
    height: 48px;
}
.rs-loader .rs-loader-vertical .rs-loader .rs-loader-content {
    line-height: 1.66666667;
}
.rs-loader-spin {
    position: relative;
    display: inline-block;
    float: left;
}
.rs-loader-spin::before,
.rs-loader-spin::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    display: block;
    border-radius: 50%;
}
.rs-loader-spin::before {
    border: 3px solid rgb(from #f7f7fa r g b / 80%);
    border: 3px solid var(--rs-loader-ring);
}
.rs-loader-spin::after {
    border-width: 3px;
    border-style: solid;
    border-color: #939393 transparent transparent;
    border-color: var(--rs-loader-rotor) transparent transparent;
    -webkit-animation: loaderSpin 0.6s infinite linear;
    animation: loaderSpin 0.6s infinite linear;
}
.rs-loader-content {
    float: left;
    display: inline-block;
}
.rs-loader-spin + .rs-loader-content {
    margin-left: 12px;
}
.rs-loader-xs .rs-loader-spin + .rs-loader-content {
    margin-left: 10px;
}
.rs-loader-backdrop {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgb(from #fff r g b / 90%);
    background: var(--rs-loader-backdrop);
}
.rs-loader-vertical {
    width: auto;
    display: inline-block;
}
.rs-loader-vertical .rs-loader {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
}
.rs-loader-vertical .rs-loader-spin {
    margin: 0 auto;
}
.rs-loader-vertical .rs-loader-content {
    line-height: 1.66666667;
    text-align: center;
}
.rs-loader-vertical .rs-loader-spin,
.rs-loader-vertical .rs-loader-content {
    display: block;
    float: none;
}
.rs-loader-vertical .rs-loader-spin + .rs-loader-content {
    margin-left: 0;
    margin-top: 10px;
}
.rs-loader-inverse .rs-loader-content {
    color: #f7f7fa;
    color: var(--rs-text-inverse);
}
.rs-loader-inverse .rs-loader-backdrop {
    background: rgb(from #121212 r g b / 83%);
    background: var(--rs-loader-backdrop-inverse);
}
.rs-loader-inverse .rs-loader-spin::before {
    border-color: rgb(from #f7f7fa r g b / 30%);
    border-color: var(--rs-loader-ring-inverse);
}
.rs-loader-inverse .rs-loader-spin::after {
    border-top-color: #fff;
    border-top-color: var(--rs-loader-rotor-inverse);
}
.rs-loader-speed-fast .rs-loader-spin::after {
    -webkit-animation-duration: 0.4s;
    animation-duration: 0.4s;
}
.rs-loader-speed-normal .rs-loader-spin::after {
    -webkit-animation-duration: 0.6s;
    animation-duration: 0.6s;
}
.rs-loader-speed-slow .rs-loader-spin::after {
    -webkit-animation-duration: 0.8s;
    animation-duration: 0.8s;
}
.rs-loader-speed-paused .rs-loader-spin::after {
    -webkit-animation-play-state: paused;
    animation-play-state: paused;
}
.rs-loader-center,
.rs-loader-backdrop-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    width: 100%;
    height: 100%;
}
.rs-loader-center .rs-loader,
.rs-loader-backdrop-wrapper .rs-loader {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}
.rs-loader-center:not(.rs-loader-vertical) .rs-loader,
.rs-loader-backdrop-wrapper:not(.rs-loader-vertical) .rs-loader {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}
.rs-loader-center.rs-loader-vertical .rs-loader,
.rs-loader-backdrop-wrapper.rs-loader-vertical .rs-loader {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}
.rs-loader-center .rs-loader-content,
.rs-loader-backdrop-wrapper .rs-loader-content {
    z-index: 1;
}
.rs-loader-lg .rs-loader-spin {
    width: 64px;
    height: 64px;
}
.rs-loader-lg .rs-loader-spin::before,
.rs-loader-lg .rs-loader-spin::after {
    width: 64px;
    height: 64px;
}
.rs-loader-lg .rs-loader-content {
    font-size: 16px;
    line-height: 4;
}
.rs-loader-lg .rs-loader-vertical .rs-loader-lg .rs-loader {
    height: 94px;
}
.rs-loader-lg .rs-loader-vertical .rs-loader-lg .rs-loader-content {
    line-height: 1.25;
}
.rs-loader-md .rs-loader-spin {
    width: 36px;
    height: 36px;
}
.rs-loader-md .rs-loader-spin::before,
.rs-loader-md .rs-loader-spin::after {
    width: 36px;
    height: 36px;
}
.rs-loader-md .rs-loader-content {
    font-size: 14px;
    line-height: 2.57142857;
}
.rs-loader-md .rs-loader-vertical .rs-loader-md .rs-loader {
    height: 66px;
}
.rs-loader-md .rs-loader-vertical .rs-loader-md .rs-loader-content {
    line-height: 1.42857143;
}
.rs-loader-sm .rs-loader-spin {
    width: 18px;
    height: 18px;
}
.rs-loader-sm .rs-loader-spin::before,
.rs-loader-sm .rs-loader-spin::after {
    width: 18px;
    height: 18px;
}
.rs-loader-sm .rs-loader-content {
    font-size: 14px;
    line-height: 1.28571429;
}
.rs-loader-sm .rs-loader-vertical .rs-loader-sm .rs-loader {
    height: 48px;
}
.rs-loader-sm .rs-loader-vertical .rs-loader-sm .rs-loader-content {
    line-height: 1.42857143;
}
.rs-loader-xs .rs-loader-spin {
    width: 16px;
    height: 16px;
}
.rs-loader-xs .rs-loader-spin::before,
.rs-loader-xs .rs-loader-spin::after {
    width: 16px;
    height: 16px;
}
.rs-loader-xs .rs-loader-content {
    font-size: 12px;
    line-height: 1.33333333;
}
.rs-loader-xs .rs-loader-vertical .rs-loader-xs .rs-loader {
    height: 46px;
}
.rs-loader-xs .rs-loader-vertical .rs-loader-xs .rs-loader-content {
    line-height: 1.66666667;
}
.rs-loader-wrapper {
    display: inline-block;
    width: auto;
    line-height: 0;
}
@-webkit-keyframes loaderSpin {
    from {
        -webkit-transform: rotate(0);
        transform: rotate(0);
    }
    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@keyframes loaderSpin {
    from {
        -webkit-transform: rotate(0);
        transform: rotate(0);
    }
    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
.rs-input {
    display: block;
    width: 100%;
    color: #343434;
    color: var(--rs-text-primary);
    background-color: #fff;
    background-color: var(--rs-input-bg);
    background-image: none;
    border: 1px solid #e5e5ea;
    border: 1px solid var(--rs-border-primary);
    -webkit-transition: border-color ease-in-out 0.15s;
    transition: border-color ease-in-out 0.15s;
    border-radius: 6px;
    /* stylelint-disable */
    padding: 7px 11px;
    font-size: 14px;
    line-height: 1.42857143;
    /* stylelint-enable */
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .rs-input {
        height: 36px;
    }
}
textarea.rs-input {
    height: auto;
}
.rs-theme-high-contrast .rs-input {
    -webkit-transition: none;
    transition: none;
}
.rs-input::-webkit-input-placeholder {
    color: #717273;
    color: var(--rs-text-secondary);
}
.rs-input::-moz-placeholder {
    color: #717273;
    color: var(--rs-text-secondary);
}
.rs-input::-ms-input-placeholder {
    color: #717273;
    color: var(--rs-text-secondary);
}
.rs-input::placeholder {
    color: #717273;
    color: var(--rs-text-secondary);
}
.rs-input:focus,
.rs-input:hover:not(:disabled) {
    border-color: #3498ff;
    border-color: var(--rs-input-focus-border);
}
.rs-input:focus {
    outline: 3px solid rgb(from #3498ff r g b / 25%);
    outline: 3px solid var(--rs-color-focus-ring);
}
.rs-theme-high-contrast .rs-input:focus {
    outline-offset: 2px;
}
.rs-input:disabled {
    background-color: #f7f7fa;
    background-color: var(--rs-input-disabled-bg);
    color: #717273;
    color: var(--rs-text-disabled);
}
textarea.rs-input {
    overflow: auto;
    resize: vertical;
}
.rs-input-xs {
    /* stylelint-disable */
    padding: 1px 11px;
    font-size: 12px;
    line-height: 1.66666667;
    /* stylelint-enable */
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .rs-input-xs {
        height: 24px;
    }
}
textarea.rs-input-xs {
    height: auto;
}
.rs-input-lg {
    /* stylelint-disable */
    padding: 9px 11px;
    font-size: 16px;
    line-height: 1.375;
    /* stylelint-enable */
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .rs-input-lg {
        height: 42px;
    }
}
textarea.rs-input-lg {
    height: auto;
}
.rs-input-sm {
    /* stylelint-disable */
    padding: 4px 11px;
    font-size: 14px;
    line-height: 1.42857143;
    /* stylelint-enable */
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .rs-input-sm {
        height: 30px;
    }
}
textarea.rs-input-sm {
    height: auto;
}
.rs-input-group {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    border-radius: 6px;
    -webkit-transition: border-color ease-in-out 0.15s;
    transition: border-color ease-in-out 0.15s;
    width: 100%;
    cursor: text;
    color: #343434;
    color: var(--rs-text-primary);
    border: 1px solid #e5e5ea;
    border: 1px solid var(--rs-border-primary);
}
.rs-theme-high-contrast .rs-input-group {
    -webkit-transition: none;
    transition: none;
}
.rs-input-group:not(.rs-input-group-disabled):hover,
.rs-input-group:not(.rs-input-group-disabled).rs-input-group-focus,
.rs-input-group:focus-within {
    border-color: #3498ff;
    border-color: var(--rs-input-focus-border);
}
.rs-input-group:not(.rs-input-group-disabled).rs-input-group-focus,
.rs-input-group:focus-within {
    outline: 3px solid rgb(from #3498ff r g b / 25%);
    outline: 3px solid var(--rs-color-focus-ring);
}
.rs-theme-high-contrast .rs-input-group:not(.rs-input-group-disabled).rs-input-group-focus,
.rs-theme-high-contrast .rs-input-group:focus-within {
    outline-offset: 2px;
}
.rs-input-group .rs-input-group {
    outline: none !important;
    border-color: transparent !important;
}
.rs-input-group .rs-input-number,
.rs-input-group .rs-input-group-addon,
.rs-input-group .rs-input-group-btn,
.rs-input-group .rs-picker-date .rs-picker-toggle {
    border: none;
    border-radius: 0;
    outline: none;
}
.rs-input-group:not(.rs-input-group-inside) .rs-input {
    border: none;
    border-radius: 0;
    outline: none;
}
.rs-input-group:not(.rs-input-group-inside) .rs-input-number:not(:last-child) .rs-input-number-btn-group-vertical {
    border-radius: 0;
}
.rs-input-group:not(.rs-input-group-inside) .rs-input-number:not(:last-child) .rs-input-number-touchspin-up {
    border-top-right-radius: 0;
}
.rs-input-group:not(.rs-input-group-inside) .rs-input-number:not(:last-child) .rs-input-number-touchspin-down {
    border-bottom-right-radius: 0;
}
.rs-input-group:not(.rs-input-group-inside) .rs-input-number:not(:first-child) .rs-input {
    border-radius: 0 !important;
}
.rs-input-group:not(.rs-input-group-inside) > :first-child,
.rs-input-group:not(.rs-input-group-inside) .rs-auto-complete:first-child .rs-input,
.rs-input-group:not(.rs-input-group-inside) .rs-picker-date:first-child .rs-picker-toggle,
.rs-input-group:not(.rs-input-group-inside) .rs-form-control-wrapper:first-child > .rs-input {
    border-bottom-left-radius: 6px;
    border-top-left-radius: 6px;
}
.rs-input-group:not(.rs-input-group-inside) > :last-child,
.rs-input-group:not(.rs-input-group-inside) .rs-auto-complete:last-child .rs-input,
.rs-input-group:not(.rs-input-group-inside) .rs-picker-date:last-child .rs-picker-toggle,
.rs-input-group:not(.rs-input-group-inside) .rs-form-control-wrapper:last-child > .rs-input {
    border-bottom-right-radius: 6px;
    border-top-right-radius: 6px;
}
.rs-input-group > .rs-input,
.rs-input-group > .rs-form-control-wrapper {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
}
.rs-input-group .rs-form-control-wrapper > .rs-input {
    width: 100%;
}
.rs-input-group > .rs-input {
    position: relative;
}
.rs-input-group input.rs-input ~ .rs-input-group-addon,
.rs-input-group .rs-form-control-wrapper ~ .rs-input-group-addon {
    border-left: none;
    left: auto;
    right: 0;
}
.rs-input-group input.rs-input ~ .rs-input-group-addon:not(:last-child),
.rs-input-group .rs-form-control-wrapper ~ .rs-input-group-addon:not(:last-child) {
    border-right: 0;
}
.rs-input-group.rs-input-group-inside {
    width: 100%;
    background-color: #fff;
    background-color: var(--rs-input-bg);
}
.rs-input-group.rs-input-group-inside .rs-input {
    display: block;
    width: 100%;
    border: none;
    outline: none;
}
.rs-input-group.rs-input-group-inside .rs-input-group-btn,
.rs-input-group.rs-input-group-inside .rs-input-group-addon {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
}
.rs-input-group.rs-input-group-inside .rs-input-group-btn {
    color: #343434;
    color: var(--rs-btn-subtle-text);
    background-color: transparent;
    border: none;
    color: inherit;
    height: 34px;
    padding: 7px 11px;
    border-radius: 0 6px 6px 0;
}
.rs-input-group.rs-input-group-inside .rs-input-group-btn:hover,
.rs-input-group.rs-input-group-inside .rs-input-group-btn:focus {
    color: #343434;
    color: var(--rs-btn-subtle-hover-text);
    background-color: #e5e5ea;
    background-color: var(--rs-btn-subtle-hover-bg);
}
.rs-input-group.rs-input-group-inside .rs-input-group-btn:active,
.rs-input-group.rs-input-group-inside .rs-input-group-btn.rs-btn-active {
    color: #121212;
    color: var(--rs-btn-subtle-active-text);
    background-color: #e5e5ea;
    background-color: var(--rs-btn-subtle-active-bg);
}
.rs-input-group.rs-input-group-inside .rs-input-group-btn:disabled,
.rs-input-group.rs-input-group-inside .rs-input-group-btn.rs-btn-disabled {
    color: #b6b7b8;
    color: var(--rs-btn-subtle-disabled-text);
    background: none;
}
.rs-theme-high-contrast .rs-input-group.rs-input-group-inside .rs-input-group-btn:disabled,
.rs-theme-high-contrast .rs-input-group.rs-input-group-inside .rs-input-group-btn.rs-btn-disabled {
    border-color: var(--rs-btn-default-disabled-boreder-color);
}
.rs-input-group.rs-input-group-inside .rs-input-group-btn > .rs-icon {
    font-size: 14px;
    line-height: 1.42857143;
}
.rs-input-group.rs-input-group-inside .rs-input-group-btn:focus {
    outline: none;
}
.rs-input-group.rs-input-group-inside .rs-input-group-btn:first-child {
    border-bottom-left-radius: 6px;
    border-top-left-radius: 6px;
}
.rs-input-group.rs-input-group-inside .rs-input-group-btn:last-child {
    border-bottom-right-radius: 6px;
    border-top-right-radius: 6px;
}
.rs-input-group.rs-input-group-inside .rs-input-group-addon {
    top: 0;
    background: none;
    border: none;
    padding: 10px 12px;
}
.rs-input-group.rs-input-group-inside .rs-input-group-addon.rs-input-group-btn {
    padding: 8px 13px;
}
.rs-input-group.rs-input-group-inside .rs-input-group-btn ~ input.rs-input,
.rs-input-group.rs-input-group-inside .rs-input-group-addon ~ input.rs-input,
.rs-input-group.rs-input-group-inside .rs-input-group-btn ~ .rs-auto-complete > input.rs-input,
.rs-input-group.rs-input-group-inside .rs-input-group-addon ~ .rs-auto-complete > input.rs-input,
.rs-input-group.rs-input-group-inside .rs-input-group-addon ~ .rs-form-control-wrapper > input.rs-input,
.rs-input-group.rs-input-group-inside .rs-input-group-btn ~ .rs-form-control-wrapper > input.rs-input {
    padding-left: 0;
    padding-right: 12px;
}
.rs-input-group.rs-input-group-inside input.rs-input ~ .rs-input-group-btn,
.rs-input-group.rs-input-group-inside .rs-auto-complete ~ .rs-input-group-addon,
.rs-input-group.rs-input-group-inside .rs-form-control-wrapper ~ .rs-input-group-addon,
.rs-input-group.rs-input-group-inside .rs-form-control-wrapper ~ .rs-input-group-btn {
    left: inherit;
    right: 0;
}
.rs-input-group.rs-input-group-inside .rs-auto-complete ~ .rs-input-group-btn,
.rs-input-group.rs-input-group-inside .rs-form-control-wrapper ~ .rs-input-group-btn {
    left: inherit;
    right: 1px;
}
.rs-input-group.rs-input-group-disabled {
    background-color: #f7f7fa;
    background-color: var(--rs-input-disabled-bg);
    color: #717273;
    color: var(--rs-text-disabled);
    cursor: not-allowed;
}
.rs-input-group.rs-input-group-disabled .rs-input,
.rs-input-group.rs-input-group-disabled .rs-input-group-btn,
.rs-input-group.rs-input-group-disabled .rs-input-group-addon {
    color: inherit;
}
.rs-input-group-lg > .rs-input {
    /* stylelint-disable */
    padding: 9px 11px;
    font-size: 16px;
    line-height: 1.375;
    /* stylelint-enable */
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .rs-input-group-lg > .rs-input {
        height: 42px;
    }
}
textarea.rs-input-group-lg > .rs-input {
    height: auto;
}
.rs-input-group-lg.rs-input-group-inside > .rs-input {
    padding-right: 0;
}
.rs-input-group-lg.rs-input-group > .rs-input {
    height: 40px;
}
.rs-input-group-lg.rs-input-group > .rs-input-group-addon {
    height: 40px;
}
.rs-input-group-lg.rs-input-group > .rs-input-group-addon > .rs-icon {
    font-size: inherit;
}
.rs-input-group-lg.rs-input-group:not(.rs-input-group-inside) > .rs-input-group-addon {
    padding-top: 8px;
    padding-bottom: 8px;
}
.rs-input-group-md > .rs-input {
    /* stylelint-disable */
    padding: 7px 11px;
    font-size: 14px;
    line-height: 1.42857143;
    /* stylelint-enable */
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .rs-input-group-md > .rs-input {
        height: 36px;
    }
}
textarea.rs-input-group-md > .rs-input {
    height: auto;
}
.rs-input-group-md.rs-input-group-inside > .rs-input {
    padding-right: 0;
}
.rs-input-group-md.rs-input-group > .rs-input {
    height: 34px;
}
.rs-input-group-md.rs-input-group > .rs-input-group-addon {
    height: 34px;
}
.rs-input-group-md.rs-input-group > .rs-input-group-addon > .rs-icon {
    font-size: inherit;
}
.rs-input-group-md.rs-input-group:not(.rs-input-group-inside) > .rs-input-group-addon {
    padding-top: 6px;
    padding-bottom: 6px;
}
.rs-input-group-sm > .rs-input {
    /* stylelint-disable */
    padding: 4px 11px;
    font-size: 14px;
    line-height: 1.42857143;
    /* stylelint-enable */
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .rs-input-group-sm > .rs-input {
        height: 30px;
    }
}
textarea.rs-input-group-sm > .rs-input {
    height: auto;
}
.rs-input-group-sm.rs-input-group-inside > .rs-input {
    padding-right: 0;
}
.rs-input-group-sm.rs-input-group > .rs-input {
    height: 28px;
}
.rs-input-group-sm.rs-input-group > .rs-input-group-addon {
    height: 28px;
}
.rs-input-group-sm.rs-input-group > .rs-input-group-addon > .rs-icon {
    font-size: inherit;
}
.rs-input-group-sm.rs-input-group:not(.rs-input-group-inside) > .rs-input-group-addon {
    padding-top: 3px;
    padding-bottom: 3px;
}
.rs-input-group-xs > .rs-input {
    /* stylelint-disable */
    padding: 1px 11px;
    font-size: 12px;
    line-height: 1.66666667;
    /* stylelint-enable */
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .rs-input-group-xs > .rs-input {
        height: 24px;
    }
}
textarea.rs-input-group-xs > .rs-input {
    height: auto;
}
.rs-input-group-xs.rs-input-group-inside > .rs-input {
    padding-right: 0;
}
.rs-input-group-xs.rs-input-group > .rs-input {
    height: 22px;
}
.rs-input-group-xs.rs-input-group > .rs-input-group-addon {
    height: 22px;
}
.rs-input-group-xs.rs-input-group > .rs-input-group-addon > .rs-icon {
    font-size: inherit;
}
.rs-input-group.rs-input-group-lg > .rs-input-group-addon {
    /* stylelint-disable */
    padding: 9px 11px;
    font-size: 16px;
    line-height: 1.375;
    /* stylelint-enable */
    min-width: 44px;
    padding-left: 15px;
    padding-right: 15px;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .rs-input-group.rs-input-group-lg > .rs-input-group-addon {
        height: 42px;
    }
}
textarea.rs-input-group.rs-input-group-lg > .rs-input-group-addon {
    height: auto;
}
.rs-input-group.rs-input-group-md > .rs-input-group-addon {
    /* stylelint-disable */
    padding: 7px 11px;
    font-size: 14px;
    line-height: 1.42857143;
    /* stylelint-enable */
    min-width: 36px;
    padding-left: 11px;
    padding-right: 11px;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .rs-input-group.rs-input-group-md > .rs-input-group-addon {
        height: 36px;
    }
}
textarea.rs-input-group.rs-input-group-md > .rs-input-group-addon {
    height: auto;
}
.rs-input-group.rs-input-group-sm > .rs-input-group-addon {
    /* stylelint-disable */
    padding: 4px 11px;
    font-size: 14px;
    line-height: 1.42857143;
    /* stylelint-enable */
    min-width: 32px;
    padding-left: 9px;
    padding-right: 9px;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .rs-input-group.rs-input-group-sm > .rs-input-group-addon {
        height: 30px;
    }
}
textarea.rs-input-group.rs-input-group-sm > .rs-input-group-addon {
    height: auto;
}
.rs-input-group.rs-input-group-xs > .rs-input-group-addon {
    /* stylelint-disable */
    padding: 1px 11px;
    font-size: 12px;
    line-height: 1.66666667;
    /* stylelint-enable */
    min-width: 28px;
    padding-left: 7px;
    padding-right: 7px;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .rs-input-group.rs-input-group-xs > .rs-input-group-addon {
        height: 24px;
    }
}
textarea.rs-input-group.rs-input-group-xs > .rs-input-group-addon {
    height: auto;
}
.rs-input-group-inside.rs-input-group-lg > .rs-input-group-btn {
    /* stylelint-disable */
    padding: 9px 11px;
    font-size: 16px;
    line-height: 1.375;
    /* stylelint-enable */
    height: 40px;
    padding: 9px 15px;
    border-radius: 0 6px 6px 0;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .rs-input-group-inside.rs-input-group-lg > .rs-input-group-btn {
        height: 42px;
    }
}
textarea.rs-input-group-inside.rs-input-group-lg > .rs-input-group-btn {
    height: auto;
}
.rs-input-group-inside.rs-input-group-lg > .rs-input-group-btn > .rs-icon {
    font-size: 16px;
    line-height: 1.375;
}
.rs-input-group-inside.rs-input-group-md > .rs-input-group-btn {
    /* stylelint-disable */
    font-size: 14px;
    line-height: 1.42857143;
    /* stylelint-enable */
    height: 34px;
    padding: 7px 11px;
    border-radius: 0 6px 6px 0;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .rs-input-group-inside.rs-input-group-md > .rs-input-group-btn {
        height: 36px;
    }
}
textarea.rs-input-group-inside.rs-input-group-md > .rs-input-group-btn {
    height: auto;
}
.rs-input-group-inside.rs-input-group-md > .rs-input-group-btn > .rs-icon {
    font-size: 14px;
    line-height: 1.42857143;
}
.rs-input-group-inside.rs-input-group-sm > .rs-input-group-btn {
    /* stylelint-disable */
    padding: 4px 11px;
    font-size: 14px;
    line-height: 1.42857143;
    /* stylelint-enable */
    height: 28px;
    padding: 4px 9px;
    border-radius: 0 6px 6px 0;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .rs-input-group-inside.rs-input-group-sm > .rs-input-group-btn {
        height: 30px;
    }
}
textarea.rs-input-group-inside.rs-input-group-sm > .rs-input-group-btn {
    height: auto;
}
.rs-input-group-inside.rs-input-group-sm > .rs-input-group-btn > .rs-icon {
    font-size: 12px;
    line-height: 1.66666667;
}
.rs-input-group-inside.rs-input-group-xs > .rs-input-group-btn {
    /* stylelint-disable */
    padding: 1px 11px;
    font-size: 12px;
    line-height: 1.66666667;
    /* stylelint-enable */
    height: 22px;
    padding: 1px 7px;
    border-radius: 0 6px 6px 0;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .rs-input-group-inside.rs-input-group-xs > .rs-input-group-btn {
        height: 24px;
    }
}
textarea.rs-input-group-inside.rs-input-group-xs > .rs-input-group-btn {
    height: auto;
}
.rs-input-group-inside.rs-input-group-xs > .rs-input-group-btn > .rs-icon {
    font-size: 12px;
    line-height: 1.66666667;
}
.rs-input-group-addon:not(:first-child):not(:last-child),
.rs-input-group-btn:not(:first-child):not(:last-child),
.rs-input-group:not(.rs-input-group-inside) .rs-input:not(:first-child):not(:last-child) {
    border-radius: 0;
}
.rs-input-group-addon {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    white-space: nowrap;
    vertical-align: middle;
    color: #343434;
    color: var(--rs-text-primary);
    padding: 8px 12px;
    font-size: 14px;
    font-weight: normal;
    line-height: 1;
    text-align: center;
    background-color: #f7f7fa;
    background-color: var(--rs-btn-default-bg);
    border: 1px solid #e5e5ea;
    border: 1px solid var(--rs-border-primary);
    border-radius: 6px;
}
.rs-input-group-addon.rs-input-sm {
    padding: 5px 10px;
    font-size: 12px;
    border-radius: 6px;
}
.rs-input-group-addon.rs-input-xs {
    padding: 8px 8px;
    font-size: 12px;
    border-radius: 6px;
}
.rs-input-group-addon.rs-input-lg {
    padding: 10px 16px;
    font-size: 16px;
    border-radius: 6px;
}
.rs-input-group-addon input[type='radio'],
.rs-input-group-addon input[type='checkbox'] {
    margin-top: 0;
}
.rs-input-group-btn {
    position: relative;
    line-height: 1;
    white-space: nowrap;
    border-radius: 0;
}
.rs-input-group-btn > .rs-btn {
    position: relative;
}
.rs-input-group-btn > .rs-btn + .rs-btn {
    margin-left: -1px;
}
.rs-input-group-btn:first-child > .rs-btn,
.rs-input-group-btn:first-child > .rs-btn-group {
    margin-right: -1px;
    border-right: medium none;
}
.rs-input-group-btn:last-child > .rs-btn,
.rs-input-group-btn:last-child > .rs-btn-group {
    margin-left: -1px;
}
.rs-highlight-mark {
    background-color: #fff6c9;
    background-color: var(--rs-text-highlight-bg);
    padding: 0 0.2em;
    font-weight: bolder;
    border-radius: 4px;
}
.rs-picker-subtle .picker-subtle-toggle {
    position: relative;
    z-index: 5;
    padding-right: 32px;
    display: inline-block;
}
.rs-picker-subtle.rs-picker-disabled .picker-subtle-toggle {
    cursor: not-allowed;
}
.rs-picker-subtle.rs-picker-disabled .picker-subtle-toggle:hover,
.rs-picker-subtle.rs-picker-disabled .picker-subtle-toggle:focus,
.rs-picker-subtle.rs-picker-disabled .picker-subtle-toggle:active {
    background: none;
}
.rs-picker-subtle.rs-picker-disabled .picker-subtle-toggle:hover::after,
.rs-picker-subtle.rs-picker-disabled .picker-subtle-toggle:focus::after,
.rs-picker-subtle.rs-picker-disabled .picker-subtle-toggle:active::after {
    display: none;
}
.rs-picker-toggle-wrapper {
    display: inline-block;
    vertical-align: middle;
    max-width: 100%;
}
.rs-picker-toggle.rs-btn .rs-ripple-pond {
    display: none !important;
}
.rs-picker-block {
    display: block;
}
.rs-picker-disabled {
    cursor: not-allowed;
}
.rs-picker-disabled .rs-picker-toggle-value,
.rs-picker-disabled .rs-picker-toggle-indicator,
.rs-picker-disabled .rs-picker-tag-list .rs-tag {
    color: #717273 !important;
    color: var(--rs-text-disabled) !important;
}
.rs-picker-disabled .rs-picker-value-count {
    opacity: 0.5;
}
.rs-picker-toggle .rs-picker-toggle-placeholder {
    color: #717273;
    color: var(--rs-text-secondary);
}
.rs-picker-has-value .rs-btn .rs-picker-toggle-value,
.rs-picker-has-value .rs-picker-toggle .rs-picker-toggle-value {
    color: #1675e0;
    color: var(--rs-picker-value);
}
.rs-picker-none {
    padding: 6px 12px 12px;
    color: #717273;
    color: var(--rs-text-secondary);
    cursor: default;
}
.rs-picker-countable .rs-picker-toggle-value {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}
.rs-picker-value-list {
    -webkit-box-flex: 0;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .rs-picker-value-list {
        max-width: 100%;
    }
}
.rs-picker-value-count {
    margin: 0 4px;
    background-color: #3498ff;
    background-color: var(--rs-picker-count-bg);
    color: #fff;
    color: var(--rs-picker-count-text);
    border-radius: 10px;
    padding: 0 8px;
    line-height: 20px;
}
.rs-picker-value-separator {
    margin: 0 4px 0 0;
}
.rs-picker:not(.rs-picker-disabled):hover,
.rs-picker.rs-picker-focused {
    border-color: #3498ff;
    border-color: var(--rs-input-focus-border);
}
.rs-picker-toggle-active,
.rs-picker.rs-picker-focused {
    -webkit-box-shadow: 0 0 0 3px rgb(from #3498ff r g b / 25%);
    -webkit-box-shadow: var(--rs-state-focus-shadow);
    box-shadow: 0 0 0 3px rgb(from #3498ff r g b / 25%);
    box-shadow: var(--rs-state-focus-shadow);
}
.rs-picker-label.rs-input-group-addon {
    color: #343434;
    color: var(--rs-text-primary);
    padding-right: 4px !important;
}
.rs-picker-loader {
    width: 14px;
    height: 14px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}
.rs-picker-error > .rs-picker-input-group {
    border-color: #f44336;
    border-color: var(--rs-picker-state-error);
}
.rs-picker-error > .rs-picker-input-group:hover {
    border-color: #f44336;
    border-color: var(--rs-picker-state-error);
}
.rs-picker-error > .rs-picker-input-group:focus-within {
    border-color: #f44336;
    border-color: var(--rs-picker-state-error);
    outline: 3px solid rgb(from #f44336 r g b / 25%);
    outline: var(--rs-picker-state-error-outline);
}
.rs-picker-toggle {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-bottom: 0;
    font-weight: normal;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    white-space: nowrap;
    -webkit-transition: color 0.15s ease-out, background-color 0.15s ease-out;
    transition: color 0.15s ease-out, background-color 0.15s ease-out;
    border: none;
    border: var(--rs-btn-default-border, none);
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    text-decoration: none;
    color: #343434;
    color: var(--rs-btn-default-text);
    background-color: #f7f7fa;
    background-color: var(--rs-btn-default-bg);
    border-radius: 6px;
    font-size: 14px;
    line-height: 20px;
    padding: 8px 12px;
    overflow: hidden;
    position: relative;
}
.rs-theme-high-contrast .rs-picker-toggle {
    -webkit-transition: none;
    transition: none;
}
.rs-btn-ghost.rs-picker-toggle {
    padding: 7px 11px;
}
.rs-btn-icon.rs-picker-toggle {
    padding: 10px 10px;
    line-height: 16px;
}
.rs-btn-icon.rs-picker-toggle > .rs-icon {
    font-size: 16px;
}
.rs-btn-icon-with-text.rs-picker-toggle {
    line-height: 20px;
}
.rs-btn-icon-with-text.rs-picker-toggle > .rs-icon {
    padding: 10px 10px;
    width: 36px;
    height: 36px;
}
.rs-btn-icon-with-text.rs-picker-toggle.rs-btn-icon-placement-left {
    padding: 8px 12px 8px 48px;
}
.rs-btn-icon-with-text.rs-picker-toggle.rs-btn-icon-placement-left > .rs-icon {
    left: 0;
    border-right: none;
    border-right: var(--rs-btn-default-border, none);
}
.rs-btn-icon-with-text.rs-picker-toggle.rs-btn-icon-placement-right {
    padding: 8px 48px 8px 12px;
}
.rs-btn-icon-with-text.rs-picker-toggle.rs-btn-icon-placement-right > .rs-icon {
    right: 0;
    border-left: none;
    border-left: var(--rs-btn-default-border, none);
}
.rs-picker-toggle:focus-visible {
    outline: 3px solid rgb(from #3498ff r g b / 25%);
    outline: 3px solid var(--rs-color-focus-ring);
}
.rs-theme-high-contrast .rs-picker-toggle:focus-visible {
    outline-offset: 2px;
}
.rs-picker-toggle:hover,
.rs-picker-toggle:focus {
    color: var(--rs-btn-default-hover-text);
    background-color: #e5e5ea;
    background-color: var(--rs-btn-default-hover-bg);
    text-decoration: none;
}
.rs-picker-toggle:active,
.rs-picker-toggle.rs-btn-active {
    color: #121212;
    color: var(--rs-btn-default-active-text);
    background-color: #d9d9d9;
    background-color: var(--rs-btn-default-active-bg);
}
.rs-picker-toggle:disabled,
.rs-picker-toggle.rs-btn-disabled {
    cursor: not-allowed;
    color: #717273;
    color: var(--rs-btn-default-disabled-text);
    background-color: #f7f7fa;
    background-color: var(--rs-btn-default-disabled-bg);
}
.rs-theme-high-contrast .rs-picker-toggle:disabled,
.rs-theme-high-contrast .rs-picker-toggle.rs-btn-disabled {
    border-color: var(--rs-btn-default-disabled-boreder-color);
}
@media not all and (min-resolution: 0.001dpcm) {
    .rs-picker-toggle {
        /* stylelint-disable */
        -webkit-mask-image: -webkit-radial-gradient(white, black);
        /* stylelint-enable */
    }
}
.rs-picker-default .rs-picker-toggle {
    padding: 7px 11px;
}
.rs-picker-toggle-textbox {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    border: 1px solid #0000;
    padding-left: 10px;
    padding-right: 32px;
    color: #343434;
    color: var(--rs-text-primary);
    background-color: #fff;
    background-color: var(--rs-input-bg);
    outline: none;
}
.rs-picker-toggle.rs-btn-lg .rs-picker-toggle-textbox {
    padding-left: 14px;
}
.rs-picker-toggle.rs-btn-sm .rs-picker-toggle-textbox {
    padding-left: 8px;
}
.rs-picker-toggle.rs-btn-xs .rs-picker-toggle-textbox {
    padding-left: 6px;
}
.rs-picker-toggle-read-only {
    opacity: 0;
}
.rs-picker-default .rs-picker-toggle {
    -webkit-transition: border-color ease-in-out 0.15s;
    transition: border-color ease-in-out 0.15s;
}
.rs-theme-high-contrast .rs-picker-default .rs-picker-toggle {
    -webkit-transition: none;
    transition: none;
}
.rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle:hover,
.rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle:focus,
.rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle-active {
    border-color: #3498ff;
    border-color: var(--rs-input-focus-border);
}
.rs-picker-subtle .rs-picker-toggle {
    color: #343434;
    color: var(--rs-btn-subtle-text);
    background-color: transparent;
    border: none;
    -webkit-transition: none;
    transition: none;
}
.rs-picker-subtle .rs-picker-toggle:hover,
.rs-picker-subtle .rs-picker-toggle:focus {
    color: #343434;
    color: var(--rs-btn-subtle-hover-text);
    background-color: #e5e5ea;
    background-color: var(--rs-btn-subtle-hover-bg);
}
.rs-picker-subtle .rs-picker-toggle:active,
.rs-picker-subtle .rs-picker-toggle.rs-btn-active {
    color: #121212;
    color: var(--rs-btn-subtle-active-text);
    background-color: #e5e5ea;
    background-color: var(--rs-btn-subtle-active-bg);
}
.rs-picker-subtle .rs-picker-toggle:disabled,
.rs-picker-subtle .rs-picker-toggle.rs-btn-disabled {
    color: #b6b7b8;
    color: var(--rs-btn-subtle-disabled-text);
    background: none;
}
.rs-theme-high-contrast .rs-picker-subtle .rs-picker-toggle:disabled,
.rs-theme-high-contrast .rs-picker-subtle .rs-picker-toggle.rs-btn-disabled {
    border-color: var(--rs-btn-default-disabled-boreder-color);
}
.rs-picker-subtle .rs-picker-toggle-active {
    background-color: #e5e5ea;
    background-color: var(--rs-btn-subtle-hover-bg);
    color: #343434;
    color: var(--rs-btn-subtle-hover-text);
}
/* stylelint-disable-next-line */
.rs-picker-toggle-wrapper .rs-picker-toggle.rs-btn {
    padding-right: 32px;
}
.rs-picker-has-value.rs-picker-cleanable .rs-picker-toggle.rs-btn {
    padding-right: 32px;
}
.rs-picker-toggle.rs-btn .rs-picker-toggle-indicator .rs-picker-caret-icon,
.rs-picker-toggle.rs-btn .rs-picker-toggle-indicator .rs-picker-clean {
    top: 8px;
    right: 12px;
}
.rs-picker-default .rs-picker-toggle.rs-btn .rs-picker-toggle-indicator .rs-picker-caret-icon,
.rs-picker-default .rs-picker-toggle.rs-btn .rs-picker-toggle-indicator .rs-picker-clean {
    top: 7px;
}
.rs-picker-date .rs-picker-toggle.rs-btn,
.rs-picker-daterange .rs-picker-toggle.rs-btn {
    padding-right: 36px;
}
.rs-picker-date .rs-picker-toggle.rs-btn .rs-picker-toggle-indicator .rs-picker-clean,
.rs-picker-daterange .rs-picker-toggle.rs-btn .rs-picker-toggle-indicator .rs-picker-clean,
.rs-picker-date .rs-picker-toggle.rs-btn .rs-picker-toggle-indicator .rs-picker-caret-icon,
.rs-picker-daterange .rs-picker-toggle.rs-btn .rs-picker-toggle-indicator .rs-picker-caret-icon {
    top: 8px;
    right: 12px;
}
.rs-picker-subtle .rs-picker-date .rs-picker-toggle.rs-btn .rs-picker-toggle-indicator .rs-picker-clean,
.rs-picker-subtle .rs-picker-daterange .rs-picker-toggle.rs-btn .rs-picker-toggle-indicator .rs-picker-clean,
.rs-picker-subtle .rs-picker-date .rs-picker-toggle.rs-btn .rs-picker-toggle-indicator .rs-picker-caret-icon,
.rs-picker-subtle .rs-picker-daterange .rs-picker-toggle.rs-btn .rs-picker-toggle-indicator .rs-picker-caret-icon {
    top: 7px;
}
.rs-picker-default .rs-picker-toggle.rs-btn {
    padding-top: 7px;
    padding-bottom: 7px;
}
.rs-picker-toggle.rs-btn-lg {
    font-size: 16px;
    line-height: 22px;
    padding: 10px 16px;
}
.rs-picker-toggle-wrapper .rs-picker-toggle.rs-btn-lg {
    padding-right: 36px;
}
.rs-picker-has-value.rs-picker-cleanable .rs-picker-toggle.rs-btn-lg {
    padding-right: 36px;
}
.rs-picker-toggle.rs-btn-lg .rs-picker-toggle-indicator .rs-picker-caret-icon,
.rs-picker-toggle.rs-btn-lg .rs-picker-toggle-indicator .rs-picker-clean {
    top: 10px;
    right: 16px;
}
.rs-picker-default .rs-picker-toggle.rs-btn-lg .rs-picker-toggle-indicator .rs-picker-caret-icon,
.rs-picker-default .rs-picker-toggle.rs-btn-lg .rs-picker-toggle-indicator .rs-picker-clean {
    top: 9px;
}
.rs-picker-default .rs-picker-toggle.rs-btn-lg {
    padding-left: 15px;
}
.rs-picker-date .rs-picker-toggle.rs-btn-lg,
.rs-picker-daterange .rs-picker-toggle.rs-btn-lg {
    padding-right: 42px;
}
.rs-picker-date .rs-picker-toggle.rs-btn-lg .rs-picker-toggle-indicator .rs-picker-clean,
.rs-picker-daterange .rs-picker-toggle.rs-btn-lg .rs-picker-toggle-indicator .rs-picker-clean,
.rs-picker-date .rs-picker-toggle.rs-btn-lg .rs-picker-toggle-indicator .rs-picker-caret-icon,
.rs-picker-daterange .rs-picker-toggle.rs-btn-lg .rs-picker-toggle-indicator .rs-picker-caret-icon {
    top: 10px;
    right: 16px;
}
.rs-picker-subtle .rs-picker-date .rs-picker-toggle.rs-btn-lg .rs-picker-toggle-indicator .rs-picker-clean,
.rs-picker-subtle .rs-picker-daterange .rs-picker-toggle.rs-btn-lg .rs-picker-toggle-indicator .rs-picker-clean,
.rs-picker-subtle .rs-picker-date .rs-picker-toggle.rs-btn-lg .rs-picker-toggle-indicator .rs-picker-caret-icon,
.rs-picker-subtle .rs-picker-daterange .rs-picker-toggle.rs-btn-lg .rs-picker-toggle-indicator .rs-picker-caret-icon {
    top: 9px;
}
.rs-picker-default .rs-picker-toggle.rs-btn-lg {
    padding-top: 9px;
    padding-bottom: 9px;
}
.rs-btn-ghost.rs-picker-toggle.rs-btn-lg {
    padding: 9px 15px;
}
.rs-btn-icon.rs-picker-toggle.rs-btn-lg {
    padding: 11px 11px;
    line-height: 20px;
}
.rs-btn-icon.rs-picker-toggle.rs-btn-lg > .rs-icon {
    font-size: 20px;
}
.rs-btn-icon-with-text.rs-picker-toggle.rs-btn-lg {
    line-height: 22px;
}
.rs-btn-icon-with-text.rs-picker-toggle.rs-btn-lg > .rs-icon {
    padding: 11px 11px;
    width: 42px;
    height: 42px;
}
.rs-btn-icon-with-text.rs-picker-toggle.rs-btn-lg.rs-btn-icon-placement-left {
    padding: 10px 16px 10px 58px;
}
.rs-btn-icon-with-text.rs-picker-toggle.rs-btn-lg.rs-btn-icon-placement-left > .rs-icon {
    left: 0;
    border-right: none;
    border-right: var(--rs-btn-default-border, none);
}
.rs-btn-icon-with-text.rs-picker-toggle.rs-btn-lg.rs-btn-icon-placement-right {
    padding: 10px 58px 10px 16px;
}
.rs-btn-icon-with-text.rs-picker-toggle.rs-btn-lg.rs-btn-icon-placement-right > .rs-icon {
    right: 0;
    border-left: none;
    border-left: var(--rs-btn-default-border, none);
}
.rs-picker-toggle.rs-btn-lg .rs-picker-value-count {
    line-height: 22px;
}
.rs-picker-toggle-wrapper .rs-picker-toggle.rs-btn-md {
    padding-right: 32px;
}
.rs-picker-has-value.rs-picker-cleanable .rs-picker-toggle.rs-btn-md {
    padding-right: 32px;
}
.rs-picker-toggle.rs-btn-md .rs-picker-toggle-indicator .rs-picker-caret-icon,
.rs-picker-toggle.rs-btn-md .rs-picker-toggle-indicator .rs-picker-clean {
    top: 8px;
    right: 12px;
}
.rs-picker-default .rs-picker-toggle.rs-btn-md .rs-picker-toggle-indicator .rs-picker-caret-icon,
.rs-picker-default .rs-picker-toggle.rs-btn-md .rs-picker-toggle-indicator .rs-picker-clean {
    top: 7px;
}
.rs-picker-default .rs-picker-toggle.rs-btn-md {
    padding-left: 11px;
}
.rs-picker-date .rs-picker-toggle.rs-btn-md,
.rs-picker-daterange .rs-picker-toggle.rs-btn-md {
    padding-right: 36px;
}
.rs-picker-date .rs-picker-toggle.rs-btn-md .rs-picker-toggle-indicator .rs-picker-clean,
.rs-picker-daterange .rs-picker-toggle.rs-btn-md .rs-picker-toggle-indicator .rs-picker-clean,
.rs-picker-date .rs-picker-toggle.rs-btn-md .rs-picker-toggle-indicator .rs-picker-caret-icon,
.rs-picker-daterange .rs-picker-toggle.rs-btn-md .rs-picker-toggle-indicator .rs-picker-caret-icon {
    top: 8px;
    right: 12px;
}
.rs-picker-subtle .rs-picker-date .rs-picker-toggle.rs-btn-md .rs-picker-toggle-indicator .rs-picker-clean,
.rs-picker-subtle .rs-picker-daterange .rs-picker-toggle.rs-btn-md .rs-picker-toggle-indicator .rs-picker-clean,
.rs-picker-subtle .rs-picker-date .rs-picker-toggle.rs-btn-md .rs-picker-toggle-indicator .rs-picker-caret-icon,
.rs-picker-subtle .rs-picker-daterange .rs-picker-toggle.rs-btn-md .rs-picker-toggle-indicator .rs-picker-caret-icon {
    top: 7px;
}
.rs-picker-default .rs-picker-toggle.rs-btn-md {
    padding-top: 7px;
    padding-bottom: 7px;
}
.rs-picker-toggle.rs-btn-sm {
    font-size: 14px;
    line-height: 20px;
    padding: 5px 10px;
}
.rs-btn-ghost.rs-picker-toggle.rs-btn-sm {
    padding: 4px 9px;
}
.rs-btn-icon.rs-picker-toggle.rs-btn-sm {
    padding: 7px 7px;
    line-height: 16px;
}
.rs-btn-icon.rs-picker-toggle.rs-btn-sm > .rs-icon {
    font-size: 16px;
}
.rs-btn-icon-with-text.rs-picker-toggle.rs-btn-sm {
    line-height: 20px;
}
.rs-btn-icon-with-text.rs-picker-toggle.rs-btn-sm > .rs-icon {
    padding: 7px 7px;
    width: 30px;
    height: 30px;
}
.rs-btn-icon-with-text.rs-picker-toggle.rs-btn-sm.rs-btn-icon-placement-left {
    padding: 5px 10px 5px 40px;
}
.rs-btn-icon-with-text.rs-picker-toggle.rs-btn-sm.rs-btn-icon-placement-left > .rs-icon {
    left: 0;
    border-right: none;
    border-right: var(--rs-btn-default-border, none);
}
.rs-btn-icon-with-text.rs-picker-toggle.rs-btn-sm.rs-btn-icon-placement-right {
    padding: 5px 40px 5px 10px;
}
.rs-btn-icon-with-text.rs-picker-toggle.rs-btn-sm.rs-btn-icon-placement-right > .rs-icon {
    right: 0;
    border-left: none;
    border-left: var(--rs-btn-default-border, none);
}
.rs-picker-toggle-wrapper .rs-picker-toggle.rs-btn-sm {
    padding-right: 30px;
}
.rs-picker-has-value.rs-picker-cleanable .rs-picker-toggle.rs-btn-sm {
    padding-right: 30px;
}
.rs-picker-toggle.rs-btn-sm .rs-picker-toggle-indicator .rs-picker-caret-icon,
.rs-picker-toggle.rs-btn-sm .rs-picker-toggle-indicator .rs-picker-clean {
    top: 5px;
    right: 10px;
}
.rs-picker-default .rs-picker-toggle.rs-btn-sm .rs-picker-toggle-indicator .rs-picker-caret-icon,
.rs-picker-default .rs-picker-toggle.rs-btn-sm .rs-picker-toggle-indicator .rs-picker-clean {
    top: 4px;
}
.rs-picker-default .rs-picker-toggle.rs-btn-sm {
    padding-left: 9px;
}
.rs-picker-date .rs-picker-toggle.rs-btn-sm,
.rs-picker-daterange .rs-picker-toggle.rs-btn-sm {
    padding-right: 34px;
}
.rs-picker-date .rs-picker-toggle.rs-btn-sm .rs-picker-toggle-indicator .rs-picker-clean,
.rs-picker-daterange .rs-picker-toggle.rs-btn-sm .rs-picker-toggle-indicator .rs-picker-clean,
.rs-picker-date .rs-picker-toggle.rs-btn-sm .rs-picker-toggle-indicator .rs-picker-caret-icon,
.rs-picker-daterange .rs-picker-toggle.rs-btn-sm .rs-picker-toggle-indicator .rs-picker-caret-icon {
    top: 4px;
    right: 10px;
}
.rs-picker-subtle .rs-picker-date .rs-picker-toggle.rs-btn-sm .rs-picker-toggle-indicator .rs-picker-clean,
.rs-picker-subtle .rs-picker-daterange .rs-picker-toggle.rs-btn-sm .rs-picker-toggle-indicator .rs-picker-clean,
.rs-picker-subtle .rs-picker-date .rs-picker-toggle.rs-btn-sm .rs-picker-toggle-indicator .rs-picker-caret-icon,
.rs-picker-subtle .rs-picker-daterange .rs-picker-toggle.rs-btn-sm .rs-picker-toggle-indicator .rs-picker-caret-icon {
    top: 3px;
}
.rs-picker-default .rs-picker-toggle.rs-btn-sm {
    padding-top: 4px;
    padding-bottom: 4px;
}
.rs-picker-toggle.rs-btn-xs {
    font-size: 12px;
    line-height: 20px;
    padding: 2px 8px;
}
.rs-btn-ghost.rs-picker-toggle.rs-btn-xs {
    padding: 1px 7px;
}
.rs-btn-icon.rs-picker-toggle.rs-btn-xs {
    padding: 6px 6px;
    line-height: 12px;
}
.rs-btn-icon.rs-picker-toggle.rs-btn-xs > .rs-icon {
    font-size: 12px;
}
.rs-btn-icon-with-text.rs-picker-toggle.rs-btn-xs {
    line-height: 20px;
}
.rs-btn-icon-with-text.rs-picker-toggle.rs-btn-xs > .rs-icon {
    padding: 6px 6px;
    width: 24px;
    height: 24px;
}
.rs-btn-icon-with-text.rs-picker-toggle.rs-btn-xs.rs-btn-icon-placement-left {
    padding: 2px 8px 2px 32px;
}
.rs-btn-icon-with-text.rs-picker-toggle.rs-btn-xs.rs-btn-icon-placement-left > .rs-icon {
    left: 0;
    border-right: none;
    border-right: var(--rs-btn-default-border, none);
}
.rs-btn-icon-with-text.rs-picker-toggle.rs-btn-xs.rs-btn-icon-placement-right {
    padding: 2px 32px 2px 8px;
}
.rs-btn-icon-with-text.rs-picker-toggle.rs-btn-xs.rs-btn-icon-placement-right > .rs-icon {
    right: 0;
    border-left: none;
    border-left: var(--rs-btn-default-border, none);
}
.rs-picker-toggle-wrapper .rs-picker-toggle.rs-btn-xs {
    padding-right: 28px;
}
.rs-picker-has-value.rs-picker-cleanable .rs-picker-toggle.rs-btn-xs {
    padding-right: 28px;
}
.rs-picker-toggle.rs-btn-xs .rs-picker-toggle-indicator .rs-picker-caret-icon,
.rs-picker-toggle.rs-btn-xs .rs-picker-toggle-indicator .rs-picker-clean {
    top: 2px;
    right: 8px;
}
.rs-picker-default .rs-picker-toggle.rs-btn-xs .rs-picker-toggle-indicator .rs-picker-caret-icon,
.rs-picker-default .rs-picker-toggle.rs-btn-xs .rs-picker-toggle-indicator .rs-picker-clean {
    top: 1px;
}
.rs-picker-default .rs-picker-toggle.rs-btn-xs {
    padding-left: 7px;
}
.rs-picker-date .rs-picker-toggle.rs-btn-xs,
.rs-picker-daterange .rs-picker-toggle.rs-btn-xs {
    padding-right: 30px;
}
.rs-picker-date .rs-picker-toggle.rs-btn-xs .rs-picker-toggle-indicator .rs-picker-clean,
.rs-picker-daterange .rs-picker-toggle.rs-btn-xs .rs-picker-toggle-indicator .rs-picker-clean,
.rs-picker-date .rs-picker-toggle.rs-btn-xs .rs-picker-toggle-indicator .rs-picker-caret-icon,
.rs-picker-daterange .rs-picker-toggle.rs-btn-xs .rs-picker-toggle-indicator .rs-picker-caret-icon {
    top: 2px;
    right: 8px;
}
.rs-picker-subtle .rs-picker-date .rs-picker-toggle.rs-btn-xs .rs-picker-toggle-indicator .rs-picker-clean,
.rs-picker-subtle .rs-picker-daterange .rs-picker-toggle.rs-btn-xs .rs-picker-toggle-indicator .rs-picker-clean,
.rs-picker-subtle .rs-picker-date .rs-picker-toggle.rs-btn-xs .rs-picker-toggle-indicator .rs-picker-caret-icon,
.rs-picker-subtle .rs-picker-daterange .rs-picker-toggle.rs-btn-xs .rs-picker-toggle-indicator .rs-picker-caret-icon {
    top: 1px;
}
.rs-picker-default .rs-picker-toggle.rs-btn-xs {
    padding-top: 1px;
    padding-bottom: 1px;
}
/* stylelint-disable-next-line */
.rs-picker-toggle {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
}
.rs-picker-default .rs-picker-toggle {
    position: relative;
    z-index: 5;
    padding-right: 32px;
    display: inline-block;
    color: #343434;
    color: var(--rs-text-primary);
    border: 1px solid #e5e5ea;
    border: 1px solid var(--rs-border-primary);
}
.rs-picker-disabled .rs-picker-toggle {
    cursor: not-allowed;
}
.rs-picker-toggle .rs-picker-default:not(.rs-picker-disabled):hover,
.rs-picker-toggle .rs-picker-default:not(.rs-picker-disabled):focus {
    border-color: #3498ff;
    border-color: var(--rs-input-focus-border);
}
.rs-picker-subtle .rs-picker-toggle {
    position: relative;
    z-index: 5;
    padding-right: 32px;
    display: inline-block;
}
.rs-picker-subtle.rs-picker-disabled .rs-picker-toggle {
    cursor: not-allowed;
}
.rs-picker-subtle.rs-picker-disabled .rs-picker-toggle:hover,
.rs-picker-subtle.rs-picker-disabled .rs-picker-toggle:focus,
.rs-picker-subtle.rs-picker-disabled .rs-picker-toggle:active {
    background: none;
}
.rs-picker-subtle.rs-picker-disabled .rs-picker-toggle:hover::after,
.rs-picker-subtle.rs-picker-disabled .rs-picker-toggle:focus::after,
.rs-picker-subtle.rs-picker-disabled .rs-picker-toggle:active::after {
    display: none;
}
.rs-picker-toggle.rs-btn {
    width: 100%;
    text-align: left;
}
.rs-picker-toggle-label {
    color: #343434;
    color: var(--rs-text-primary);
}
.rs-picker-toggle-label::after {
    content: ':';
    margin: 0 4px 0 2px;
}
.rs-picker-toggle-value {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
}
.rs-picker-cleanable.rs-picker-has-value .rs-picker-toggle {
    padding-right: 44px;
}
.rs-picker-toggle-indicator .rs-picker-clean {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-left: 2px;
    position: absolute;
    top: 8px;
    right: 30px;
    height: 20px;
    font-size: 12px;
    background: inherit;
    color: #717273;
    color: var(--rs-text-secondary);
    -webkit-transition: 0.2s color linear;
    transition: 0.2s color linear;
    cursor: pointer;
}
.rs-picker-toggle-indicator .rs-picker-clean.rs-btn-close {
    padding: 4px 0;
}
.rs-picker-date .rs-picker-toggle-indicator .rs-picker-clean,
.rs-picker-daterange .rs-picker-toggle-indicator .rs-picker-clean {
    right: 38px;
}
.rs-picker-toggle-indicator .rs-picker-caret-icon {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-left: 2px;
    position: absolute;
    top: 8px;
    right: 12px;
    height: 20px;
    font-size: 12px;
    color: #717273;
    color: var(--rs-text-secondary);
}
.rs-picker-toggle-indicator .rs-picker-loader {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-left: 2px;
    position: absolute;
    top: 8px;
    right: 12px;
    height: 20px;
    font-size: 12px;
}
.rs-picker-popup {
    position: absolute;
    text-align: left;
    z-index: 7;
    border-radius: 6px;
    background-color: #fff;
    background-color: var(--rs-bg-overlay);
    -webkit-box-shadow: 0 4px 4px rgba(0, 0, 0, 0.12), 0 0 10px rgba(0, 0, 0, 0.06);
    -webkit-box-shadow: var(--rs-shadow-overlay);
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.12), 0 0 10px rgba(0, 0, 0, 0.06);
    box-shadow: var(--rs-shadow-overlay);
    overflow: hidden;
    -webkit-transition: none;
    transition: none;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    padding: 6px 0;
}
.rs-theme-high-contrast .rs-picker-popup {
    border: 1px solid #e5e5ea;
    border: 1px solid var(--rs-border-primary);
}
.rs-modal-open .rs-picker-popup {
    z-index: 1055;
}
.rs-drawer-open .rs-picker-popup {
    z-index: 1055;
}
.rs-picker-popup.rs-picker-inline {
    position: relative;
    -webkit-box-shadow: none;
    box-shadow: none;
}
.rs-picker-popup .rs-search-box {
    padding: 6px 12px 6px;
}
.rs-picker-popup .rs-highlight .rs-highlight-mark {
    padding: 0;
}
.rs-picker-default .rs-btn,
.rs-picker-input .rs-btn,
.rs-picker-default .rs-picker-toggle,
.rs-picker-input .rs-picker-toggle {
    background-color: #fff !important;
    background-color: var(--rs-input-bg) !important;
}
.rs-picker-default.rs-picker-disabled .rs-picker-toggle,
.rs-picker-input.rs-picker-disabled .rs-picker-toggle,
.rs-picker-default.rs-picker-disabled .rs-btn,
.rs-picker-input.rs-picker-disabled .rs-btn {
    background-color: #f7f7fa !important;
    background-color: var(--rs-input-disabled-bg) !important;
}
.rs-picker-default.rs-picker-disabled .rs-input-group,
.rs-picker-input.rs-picker-disabled .rs-input-group {
    pointer-events: none;
}
.rs-check-item {
    display: block;
}
.rs-check-item:not(.rs-checkbox-disabled):hover,
.rs-check-item:focus,
.rs-check-item.rs-check-item-focus {
    background-color: rgb(from #cce9ff r g b / 50%);
    background-color: var(--rs-listbox-option-hover-bg);
    color: #1675e0;
    color: var(--rs-listbox-option-hover-text);
}
.rs-theme-high-contrast .rs-check-item:not(.rs-checkbox-disabled):hover,
.rs-theme-high-contrast .rs-check-item:focus,
.rs-theme-high-contrast .rs-check-item.rs-check-item-focus {
    outline: 3px solid rgb(from #3498ff r g b / 25%);
    outline: 3px solid var(--rs-color-focus-ring);
    outline-offset: -3px;
    outline-width: 2px;
    color: #1675e0;
    color: var(--rs-listbox-option-hover-text);
    text-decoration: underline;
}
.rs-theme-high-contrast .rs-theme-high-contrast .rs-check-item:not(.rs-checkbox-disabled):hover,
.rs-theme-high-contrast .rs-theme-high-contrast .rs-check-item:focus,
.rs-theme-high-contrast .rs-theme-high-contrast .rs-check-item.rs-check-item-focus {
    outline-offset: 2px;
}
.rs-theme-high-contrast .rs-check-item:not(.rs-checkbox-disabled):hover .rs-check-tree-node-text-wrapper,
.rs-theme-high-contrast .rs-check-item:focus .rs-check-tree-node-text-wrapper,
.rs-theme-high-contrast .rs-check-item.rs-check-item-focus .rs-check-tree-node-text-wrapper {
    text-decoration: underline;
}
.rs-check-item .rs-checkbox-checker {
    padding: 0;
}
.rs-check-item .rs-checkbox-checker > label {
    position: relative;
    cursor: pointer;
    display: block;
    padding: 8px 12px 8px 38px;
    line-height: 20px;
}
.rs-theme-high-contrast .rs-check-item .rs-checkbox-checker > label {
    -webkit-transition: none;
    transition: none;
}
.rs-check-item .rs-checkbox-checker > label::before {
    background-color: #fff;
    background-color: var(--rs-bg-overlay);
}
.rs-theme-high-contrast .rs-check-item .rs-checkbox-checker > label::before {
    background: none;
}
.rs-checkbox-disabled.rs-check-item .rs-checkbox-checker > label {
    cursor: not-allowed;
}
.grouped .rs-check-item .rs-checkbox-checker > label,
.rs-picker-check-menu-group-children .rs-check-item .rs-checkbox-checker > label {
    padding-left: 52px;
}
.rs-check-item .rs-checkbox-checker .rs-checkbox-control {
    left: 12px;
}
.grouped .rs-check-item .rs-checkbox-checker .rs-checkbox-control,
.rs-picker-check-menu-group-children .rs-check-item .rs-checkbox-checker .rs-checkbox-control {
    left: 26px;
}
.rs-picker-menu-group {
    background-color: #fff;
    background-color: var(--rs-listbox-option-group-bg);
    position: sticky;
    top: -1px;
    z-index: 1;
}
.rs-picker-check-menu-items {
    overflow-y: auto;
}
.rs-picker-check-menu-items ul {
    list-style: none;
    margin: 0;
    padding: 0;
}
.rs-picker-check-menu-items .rs-picker-menu-group:not(:first-child) {
    margin-top: 6px;
    border-top: 1px solid #e5e5ea;
    border-top: 1px solid var(--rs-border-primary);
    padding-top: 6px;
}
.rs-picker-check-menu-items .rs-picker-menu-group-title {
    padding: 8px 12px;
    padding-right: 32px;
    position: relative;
    cursor: pointer;
    color: #121212;
    color: var(--rs-text-heading);
}
.rs-picker-check-menu-items .rs-picker-menu-group-title .rs-picker-menu-group-caret {
    display: inline-block;
    margin-left: 2px;
    position: absolute;
    top: 8px;
    right: 12px;
    padding: 3px;
    color: #717273;
    color: var(--rs-text-secondary);
}
.rs-picker-check-menu-items .rs-picker-menu-group.folded .rs-picker-menu-group-caret {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
}
.rs-picker-check-menu-items .rs-check-item.rs-checkbox-checked .rs-checkbox-checker > label {
    font-weight: bold;
}
.rs-picker-check-menu-items.rs-picker-check-menu-grouped .rs-check-item .rs-checkbox-checker > label {
    padding-left: 52px;
}
.rs-picker-check-menu-items.rs-picker-check-menu-grouped .rs-check-item .rs-checkbox-checker .rs-checkbox-control {
    left: 26px;
}

/*# sourceMappingURL=index.css.map */
