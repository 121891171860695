@import "../../../styles/globals";
.card.card-request-type:hover{
  background-color: $secondary-background-color;
}
.add-client-modal{
  height: fit-content;
  //height: 90%;
  width: 90%;
  form{
   // height: calc(100% - 81px - 1rem);
    .content{
      max-height: unset;
    }
  }
}
.status-container{
  background-color: $secondary-background-color;
  border-radius: 8px;
  padding: 6px;
  width: fit-content;
  margin-left: auto;
}
.pdf-ifrane{
  width: 100%;
  height: calc(100vh - 80px - 7.1rem);
}