@import "../../../styles/globals";


.sidebar {
  display: flex;
  flex-direction: column;
  width: 220px;
  max-width: 220px;
  border-right: 1px solid $primary-border-color;
  height: inherit;
  z-index: 0;

  .links {
  }



  .logo {
    padding: 1rem 1rem;
    text-align: center;
    margin-bottom: 1rem;

    img {
      width: 80%;
    }
  }

  .menu {
    z-index: 0;
    overflow: visible auto;
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 0 1rem;
    max-height: calc(100vh - 46px - 3.6rem);
    height: -webkit-fill-available;
    width: -webkit-fill-available;

    .item {
      position: relative;
      display: flex;
      flex-direction: row;
      align-items: center;
      color: $gray-color-1;
      font-weight: 600;
      padding: 0.8rem 1rem;
      margin-bottom: 5px;
      border-radius: 12px;
      font-size: 0.9rem;

      i {
        width: 1.8rem;
        font-size: 1.1rem;
      }

      i.fa-solid.fa-caret-up, i.fa-solid.fa-caret-down {
        width: fit-content;
      }

      &:hover {
        background: $primary-color;
        color: $primary-white;
      }
      &.disabled, &.disabled:hover {
        background: unset;
        color: $gray-color-2;
      }
    }

    .subitem {
      position: relative;
      margin-left: 16px;
      display: flex;
      flex-direction: row;
      align-items: center;
      color: $gray-color-1;
      font-weight: 600;
      padding: 0.5rem 1rem;
      margin-bottom: 5px;
      border-radius: 12px;
      font-size: 0.9rem;

      i {
        width: 1.8rem;
        font-size: 1.1rem;
      }

      &:hover {
        background: $primary-color;
        color: $primary-white;
      }

      &.disabled, &.disabled:hover {
        background: unset;
        color: $gray-color-2;
      }

    }

    .react-tooltip {
 //     display: none;
      top: 10px!important;
      left: 50px!important;
      z-index: 1000;

      div{
        z-index: 1000;
      }
    }
    a.active .subitem {
      background: $primary-color;
      color: $primary-white;
    }

    a.active .item {
      background: $primary-color;
      color: $primary-white;
    }

    a.active.inactive .item {
      color: $gray-color-1;
      background: unset;
    }
  }

  .user-cont {
    display: flex;
    flex-direction: row;
    border-top: 1px solid $primary-border-color;
    padding: 1rem 0.5rem;
    align-items: center;
    cursor: pointer;
    overflow: hidden;

    &:hover {
      background: $primary-background-color;
    }

    .icon {
      width: 28px;
      min-width: 28px;
      text-align: center;
      font-size: 1rem;
      color: $primary-color;
    }

    .name {
      width: 100%;
      white-space: nowrap;
      font-size: 0.9rem;
      margin-right: 1rem;
      overflow: hidden;
    }

  }
}


@media (min-width: 576px) {
  .sidebar.mini {
    padding-top: 1rem;
    width: 80px;

    .menu {
      width: calc(80px - 2rem);


      .x-e {
        width: calc(80px - 2rem);
      }
      .subitem {
        margin-left: 0;
      }
    }

    .user-cont {
      justify-content: center;
    }
  }
}

@media (max-width: 576px) {
  .sidebar {
    background: $primary-white;
    width: 100vw;
    height: 100vh;
    position: absolute;
    z-index: 5000;
    max-width: unset;

  }
}