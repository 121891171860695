@import "../../styles/globals";

.upload-image-comp {
  display: flex;
  flex-direction: column;
  border: 1px dashed $primary-border-color;

  height: 200px;
  max-height: 200px;

  justify-content: center;
  align-items: center;
  cursor: pointer;

  .icon {
    font-size: 4rem;
    color: $primary-border-color;
  }

  .title {
    margin-top: 1rem;
    margin-right: 1rem;
    margin-left: 1rem;
    justify-content: center;
    text-align: center;
    margin-bottom: 0.3rem;
    color: $primary-color;
  }

  .info {
    font-size: 0.9rem;
  }

  .preview {
    width: 100%;
    overflow: hidden;
    text-align: center;
    position: relative;

    img {
      width: auto;
      height: 100%;
      object-fit: contain;
    }

    .overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background: rgba(255,255,255,0.6);
      display: none;
    }

    &:hover {
      .overlay {
        display: flex;
      }
    }
  }

}
.upload-file-comp {
  display: flex;
  flex-direction: column;
  border: 1px dashed $primary-border-color;

  height: 70px;
  max-height: 200px;

  justify-content: center;
  align-items: center;
  cursor: pointer;

  .icon {
    font-size: 1rem;
    color: $primary-border-color;
  }

  .title {
    margin-top: 0.4rem ;
    margin-bottom: 0.3rem;
    justify-content: center;
    text-align: center;
    color: $primary-color;
  }

  .info {
    font-size: 0.9rem;
  }

  .preview {
    width: 100%;
    overflow: hidden;
    text-align: center;
    position: relative;

    img {
      width: auto;
      height: 100%;
      object-fit: contain;
    }

    .overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background: rgba(255,255,255,0.6);
      display: none;
    }

    &:hover {
      .overlay {
        display: flex;
      }
    }
  }

}