@import "../../../../styles/globals";

.users-table{
  .table-body,.table-head{
    min-width: 540px;
  }
}

.location-icon-picker {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  .location-icon {
    width: 80px;
    text-align: center;
    border-radius: 8px;
    cursor: pointer;

    &.active {
      background: $secondary-color;
    }

    img {
      width: 60px;
      height: 60px;
    }
  }
}