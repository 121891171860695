@import "styles/globals.scss";
@import "styles/grid.scss";
@import "styles/forms.scss";
@import "styles/tables.scss";
@import "styles/alerts.scss";
@import "styles/generals.scss";

body {
  position: relative;
  margin: 0;
  font-family: 'Nunito Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $dark-color;
  font-size: 0.95rem;
  overscroll-behavior: none;

  input,textarea {
    font-family: 'Nunito Sans', sans-serif;
  }
  ::-webkit-scrollbar {
    height: 6px;
    width: 3px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: $primary-background-color;
    border-radius: 10px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: $gray-color-2;
    border-radius: 10px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: $secondary-background-color;
  }

  .tag-badge{
    height: 8px;
    width: 30px;
    border-radius: 4px;
    display: flex;
    margin-right: 4px;
  }
  .container-calendar{

    .tag-badge{
      height: 5px;
      width: 20px;
      border-radius: 4px;
      display: flex;
      margin-right: 4px;
    }
  }
}

a {
  text-decoration: none;
}
.price-input-container{
  position: relative;
  min-width: 220px;
  p{
    position: absolute;
    top: -6px;
    right: 11px;
    color: $gray-color-1;
  }
  input{
    padding-right: 46px;
  }
}
