@import "../../../styles/globals";

.secondary-navigation {
  display: flex;
  flex:1;
  flex-direction: column;
  background: $primary-white;
  box-shadow: 5px 5px 14px 5px rgba(0,0,0,0.08);
  border-radius: 10px;
  padding: 1rem 0;

  .item {
    display: flex;
    flex-direction: row;
    padding: 0.5rem 1rem;
    font-size: 0.95rem;
    color: $dark-color;
    border-left: 4px solid transparent;
    position: relative;
    align-items: center;

    .title{
      width: 100%;
    }
    .icon {
      margin-right: 0.8rem;
    }

    i {
      width: 1.8rem;
      font-size: 1.1rem;
    }

    i.fa-solid.fa-caret-up, i.fa-solid.fa-caret-down {
      width: fit-content;
      float: right;
    }

    &.disabled, &.disabled:hover {
      background: unset;
      color: $gray-color-2;
    }
  }

  .subitem {
    position: relative;
    margin-left: 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
    color: $dark-color;
    padding: 0.5rem 1rem;
    margin-bottom: 5px;
    font-size: 0.95rem;

    i {
      width: 1.4rem;
      font-size: 0.8rem;
    }

    &.disabled, &.disabled:hover {
      background: unset;
      color: $gray-color-2;
    }

  }

  a.active .item {
    border-left-color: $link-active-color;
    color: $link-active-color;
  }

}