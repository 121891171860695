@import "../../styles/globals";

.status-container{
  background-color: $secondary-background-color;
  border-radius: 8px;
  padding: 6px;
  width: fit-content;
  margin-left: auto;
}

.request.container {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: calc(100vh - 4rem);
  background: $primary-background-color;
  padding: 2rem;
  .card {
    height: calc(100vh - 5.5rem - 42px);
    .card-content{
      height: calc(100vh - 7.5rem - 65px);
      overflow-y: scroll;

    }
  }
  ::-webkit-scrollbar-thumb {
    background: $primary-shade-2;
  }

  .container-title {
    margin: 0 0 1.5rem 0;
  }

  .card {
    display: flex;
    flex:1;
    flex-direction: column;
    background: $primary-white;
    box-shadow: 5px 5px 14px 5px rgba(0,0,0,0.08);
    border-radius: 10px;

    .header {
      padding: 0 1rem 1rem 1rem;
      border-bottom: 1px solid $primary-border-color;
      font-weight: 700;
      font-size: 1rem;
      .search-input-container,.form-control, button,p{
        margin-top: 1rem;
        margin-bottom: 0;
      }

    }

    .content {
      padding: 1rem 1rem;
    }

    .footer {
      padding: 1rem 1rem;
    }
  }
}

.card.sign-offer{
  .header button{
    width: -webkit-fill-available;
  }
  iframe, .card, .row {
    height: -webkit-fill-available;
  }
}