@import "globals";

.form-label {
  font-size: 0.9rem;
  color: $dark-color;
  margin-bottom: 0.2rem;
  &.form-label-switch{
    font-weight: 600;
    font-size: 1rem;
    line-height: 20px;
    margin-bottom: 0.6rem;
  }
}
.form-control {
  display: flex;
  flex-direction: column;
  margin-bottom: 0.5rem;
}
.form-input {
  height: 2.3rem;
  padding: 0 0.5rem;
  border: 1px solid $primary-border-color;
  border-radius: 8px;
  font-size: 0.9rem;
  width: -webkit-fill-available;

  &:focus-visible {
    outline: 1px solid $primary-shade-2;
  }
}
textarea.form-input {
  padding: 0.5rem;
}

.form-required {
  color:red;
}

.tab-switch {
  display: flex;
  flex-direction: row;
  background: $primary-border-color;
  border-radius: 8px;
  padding: 0.4rem;

  &.with-content {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .tab {
    display: flex;
    flex:1;
    border-radius: 8px;
    padding: 0.4rem 0.8rem;
    margin-right: 0.5rem;
    color: $secondary-shade-2;
    font-weight: 600;
    font-size: 0.9rem;
    justify-content: center;
    cursor: pointer;
    justify-content: center;
    text-align: center;


    @media (max-width: 576px) {
      padding: 0.2rem 0.4rem;
      margin-right: 0.2rem;
    }

    &.active {
      background: $primary-white;
    }

    &:hover {
      background: $primary-white;
    }
  }
}

.tab-content {
  border: 1px solid $primary-border-color;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}